import React from 'react';

const BgAnimation = () => {
  return (
    <div className="bg-animation">
      <div className="circle-1"></div>
      <div className="circle-2"></div>
      <div className="circle-3"></div>
      <div className="circle-4"></div>
    </div>
  );
};

export default BgAnimation;
