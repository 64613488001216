import SystemInput from "../../../modules/system/system_mod--input"
import SystemQuestionAI from "../../../modules/system/system_mod--question-ai"

const SpecQuestionArea = ({
    questionNumberCurrent,
    questionNumberLast,
    questionCurrent,
    handleAddQuestionResponse,
    handleRemoveQuestionResponse,
    handleSkipQuestionResponse,
    saveEditedQuestionResponse,
    formMaxQuestions,
    formTitle,
    handleSave,
    handleClear,
    handleRender,
    finalQuestionHeader,
    finalQuestionText,
    finalBtn,
    btnTextRender,
    btnTextSave,
    booleanResponseDisabled,
    isBid
 }) => {
    return (
        <>
        <div className="atom_spec_question_area" data-aos="fade-up" data-aos-delay="1500">
            {questionNumberCurrent != 0 && questionNumberCurrent  <= formMaxQuestions ? (
                <SystemQuestionAI
                    questionCurrent={questionCurrent} 
                    questionNumberCurrent={questionNumberCurrent}
                    onSelectResponse={handleAddQuestionResponse} 
                    onRemoveResponse={handleRemoveQuestionResponse}
                    onSkipResponse={handleSkipQuestionResponse}
                    isBid={isBid}
                />
            ):(
                !formTitle ? (
                    <div className="atom_spec_question_completed" data-aos="zoom-in" data-aos-delay="600">
                        <div className="atom_system_agent error pulse"></div>
                        <div className="inner">
                            <h3>Please set a title for your tender.</h3>
                            <p>You need to provide a title before proceeding with the questions.</p>
                        </div>
                    </div>
                ):(
                    <>
                    <div className="atom_spec_question_completed" data-aos="zoom-in" data-aos-delay="600">
                        <div className="atom_system_agent"></div>
                        <div className="inner">
                            <h3>{finalQuestionHeader}</h3>
                            <p>{finalQuestionText}</p>
                        </div>
                    </div>
                    {finalBtn ?(
                        <div className="buttons" data-aos="zoom-in" data-aos-delay="700">
                            <button title="Reset Your Answers" className="btn btn_lgrey reset icon_only" onClick={handleClear} ></button>
                            <button title="Save Your Answers" className="btn btn_green save icon_only" onClick={handleSave} ></button>
                        </div>
                    ):(
                        <div className="buttons" data-aos="zoom-in" data-aos-delay="700">
                            <button title="Save Your Answers" className="btn btn_green save" onClick={handleSave}>{btnTextSave}</button>
                            <button title="Reset Your Answers" className="btn btn_coral generate" onClick={handleRender} >{btnTextRender}</button>
                        </div>
                    )}
                    </>
                )
            )}
        </div>
        <div className="atom_spec_end">
            <SystemInput
                onAddResponse={questionNumberCurrent === questionNumberLast ? handleAddQuestionResponse : saveEditedQuestionResponse} 
                disabled={booleanResponseDisabled} 
            />
        </div>
        </>
    )}

export default SpecQuestionArea;