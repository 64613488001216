import React from 'react';

const GlobalParagraph= ({ subtitle, text, padding, col }) => {
    return (
        <>
            <div className="container container_no--padding">
                <div className="row">
                    <div className={`col-xs-12 col-md-${col} ${padding}`}>
                        <h5>{subtitle}</h5>
                        <p>{text}</p>
                    </div>
                </div>
            </div>
            
        </>
    );
}

export default GlobalParagraph;