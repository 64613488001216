import React from 'react';

const SystemAnswer = ({ question,response, questionNumberCurrent, onEditResponse, isEmpty }) => {
    
    const handleClick = () => {
        onEditResponse(questionNumberCurrent);
    };

    // Ensure response is a string before using toLowerCase()
    const displayResponse = response && typeof response === 'string' ? response : 'Not Answered';

    return (
        <div className="block zoomy" >
            <div className="atom_question_mini">
                <div className="atom_system_agent mini"></div> 
                <div className="atom_question_mini_inner">
                    {question}
                </div>
            </div>
            <div className={`atom_answer ${questionNumberCurrent >= 5 ? 'hasHover': ''} ${isEmpty}`} onClick={handleClick}>
                <p dangerouslySetInnerHTML={{ __html: displayResponse }} />
            </div>
        </div>
    );
};

export default SystemAnswer;
