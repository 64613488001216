import React, { useState, useEffect, useContext } from 'react';
import GlobalSubTitle from '../global/header_comp--subtitle';
import CardCreate from '../global/card_comp--create';
import { AuthContext } from '../../../contexts/authContext';
import { fetchBids } from '../../../services/proposalService';

// Images
import editIcon from "../../../img/icons/icon_edit.svg";
import plusIcon from "../../../img/icons/icon_plus.svg";
import CardDocument from '../global/card_comp--document';

const DashboardBids = () => {
    const [bids, setBids] = useState([]); // Initialize bids as an empty array
    const [isBidOpen, setIsBidOpen] = useState(false); // State to manage toggle status
    const { user, details } = useContext(AuthContext); // Access user context

    useEffect(() => {
        const getBids = async () => {
            try {
                const data = await fetchBids(user.token);
                if(data){
                    console.log(data.result.bids.bids);
                    setBids(data.result.bids.bids);
                }
            } catch (error) {
                console.error('Error fetching bids:', error);
            }
        };
        getBids();
        
    }, [user.token]); // Update useEffect dependency

    const toggleContent = () => {
        setIsBidOpen(!isBidOpen);
    };

    return (
        <div className="container component_toggle" data-aos="fade-up">
            <div className="row">
                <div className={`col-xs-12 toggle_section ${isBidOpen ? 'open' : 'closed'}`} >
                    <GlobalSubTitle title="My Bids"/>
                    <a href="/dashboard/bid/create">
                        <div className="icon_edit">Add</div>
                    </a>
                    <div className="icon_number">{bids?.length ? bids?.length : 0}</div>
                    <img src={plusIcon} alt="toggle icon" onClick={toggleContent}/>
                </div>
            </div>
            <div className={`row toggle_content ${isBidOpen ? 'open' : 'closed'}`}>
                <CardCreate title="Create a New Bid Proposal" />
                {bids?.map(bid => (
                    <CardDocument key={bid.bid_uuid} id={bid.bid_uuid} title={bid.name} firstCreated={bid.first_created} revisions={bid.revisions} type="bid" />
                ))}
            </div>
        </div>
    );
}


export default DashboardBids;
