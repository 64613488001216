import React from 'react';
import { Link } from 'react-router-dom/dist';


const CardPrice= ({ title, subtitle, price, item1, item2, item3, url1, cta1, url2, cta2, color }) => {
    return (
        <div className="col-xs-12 col-md-4 np atom_upper">
            <div className={`atom_cards atom_cards_price atom_cards_price_${color}`}>
                <div className="title">{title}</div>
                <p>{subtitle}</p>
                <div className="price">{price}</div>
                <ul>
                    <li>{item1}</li>
                    <li>{item2}</li>
                    <li>{item3}</li>
                </ul>
                <div className="buttons">
                    <Link to={url1}>
                        <div className="btn btn_green">{cta1}</div>
                    </Link>
                    <Link to={url2}>
                        <div className="btn btn_green">{cta2}</div>
                    </Link>
                </div>
            </div>
        </div>
       
    );
}

export default CardPrice;