import React, { useState } from 'react';

// Images
import logoIcon from '../../../img/icons/stm_logo_icon.svg';
import createIcon from '../../../img/icons/icon_create.svg';
import fileUploadIcon from '../../../img/icons/icon_file_upload.svg';
import fileCheckIcon from '../../../img/icons/icon_file_check.svg';
import planIcon from '../../../img/icons/icon_plan.svg';
import dashboardIcon from '../../../img/icons/icon_dashboard.svg';
import { Link } from 'react-router-dom/dist';


const Navigation= ({active, open, mobile }) => {

    return (
        <div className={`layout_navigation ${mobile? 'mobile' : ''} bg_blue ${open? 'open' : 'closed'}`}>
                <a href="/dashboard">
                    <img src={logoIcon} alt="Search the Marketplace" />
                </a>
                <div className="icons">
                    <Link to="/dashboard" className={`icon ${active === 'dashboard' ? 'active' : ''}`}>
                        <img src={dashboardIcon} alt="Dashboard" title="Dashboard" />
                    </Link>
                    <Link to="/dashboard/tender/create" className={`icon ${active === 'tender' ? 'active' : ''}`}>
                        <img src={createIcon} alt="Create a Tender"  title="Create a Tender" />
                    </Link>
                    <Link to="/dashboard/bid/create" className={`icon ${active === 'bid' ? 'active' : ''}`}>
                        <img src={fileUploadIcon} alt="Create a Bid Proposal" title="Create a Bid Proposal" />
                    </Link>
                    <Link to="/dashboard/profile/plan/upgrade" className={`icon ${active === 'plan' ? 'active' : ''}`}>
                        <img src={planIcon} alt="Upgrade your Plan" title="Create a Bid Proposal" />
                    </Link>
                    <Link to="/dashboard/profile/plan" className={`icon ${active === 'bills' ? 'active' : ''}`}>
                        <img src={fileCheckIcon} alt="View Bills" title="Create a Bid Proposal" />
                    </Link>
                </div>
                <div className="avatar">
                    <Link to="/dashboard/profile/settings">
                        <div className="inner"></div>
                    </Link>
                </div>
        </div>

    );
}

export default Navigation;