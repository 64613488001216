import React, { useState, useEffect } from 'react';

const SpecProgressBar = ({ progressBar, maxQuestions }) => {
  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    console.log(progressBar);
    setProgressPercentage(progressBar);
  }, [progressBar, maxQuestions]);

  const getProgressColor = (percentage) => {
    if (percentage < 45) return 'bg_red';
    if (percentage < 75) return 'bg_blue';
    return 'bg_green';
  };

  const getProgressMessage = (percentage) => {
    if (percentage === 100) return 'Status: Excellent!';
    if (percentage > 75) return 'Status: Very Good';
    if (percentage > 45) return 'Status: OK';
    return '';
  };

  

  return (

      <div className="atom_progress">
        {progressPercentage > 0 && (
          <div
            className={`progress-bar ${getProgressColor(progressPercentage)}`}
            style={{
              width: `${progressPercentage}%`,
            }}
          >
            <p>{getProgressMessage(progressPercentage)}</p>
          </div>
        )}
      </div>
  );
};

export default SpecProgressBar;
