import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Components

import { fetchPlan, fetchTransaction } from '../../../services/stripeService';
import { AuthContext } from '../../../contexts/authContext';
import { Link } from 'react-router-dom/dist';
import Navigation from '../../components/global/nav_comp--main';
import AccountDropdown from '../../components/global/nav_comp--account';
import GlobalSubTitle from '../../components/global/header_comp--subtitle';
import CurrentPlan from '../../components/profile/plan/profile_comp--plan-current';
import PreviousPlans from '../../components/profile/plan/profile_comp--plan-previous';
import BgAnimation from '../../modules/general/animation_bg--circle';

const Profile_PlanPage = () => {
    const [planDetails, setPlanDetails] = useState(null);
    const [error, setError] = useState(null);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const getTransaction = async () => {
                // get the user current  plan
                try {
                    const data = await fetchPlan(user.token);
                    if(data){
                        setPlanDetails(data.user.plan);
                    }
                } catch (error) {
                    console.error('Error fetching user plan:', error);
                }
            
        };
        getTransaction();
       
    }, [user.token]);

    return (
        <>
            <BgAnimation/>
            <div className="container container_width--full layout_profile">
                <div className="row layout_main">
                    <AccountDropdown/>
                    <div className="col-xs-12 col-sm-1 mobile_hide">
                        <Navigation mobile={false} active="plan"/>
                    </div>
                    <div className="col-xs-12 col-sm-11 ">
                        <GlobalSubTitle title="Billing"/>
                        <div className="container">
                            <div className="row np">
                                <div className="col-xs-12 col-md-8 np">
                                    <CurrentPlan/>
                                </div>
                                <div className="col-xs-12 col-md-4 np buttons_wrap">
                                    <Link to="/dashboard/plan"><div className="btn btn_green">Manage Subscription</div></Link>
                                    <Link to="/dashboard/profile/delete"> <div className="btn btn_white">Delete Account</div></Link>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <GlobalSubTitle title="Purchase History"/>
                            <div className="row ">
                                <div className="col-xs-12 np">
                                    <PreviousPlans/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile_PlanPage;
