import React, { useState } from 'react';

const MultiInput = ({ items, setItems, label }) => {
    const handleItemChange = (id, value) => {
        if(value !== ''){
            setItems((prevItems) =>
                prevItems.map((item) =>
                    item.id === id ? { ...item, value } : item
                )
            );
        }
    };

    const addItemField = () => {
        setItems((prevItems) => [
            ...prevItems,
            { id: prevItems.length + 1, value: '' }
        ]);
    };

    return (
        <div className='fieldset'>
            {items.map((item) => (
                <div className="fieldset multi" key={item.id}>
                    <input
                        type="text"
                        value={item.value}
                        placeholder='.....'
                        onChange={(e) => handleItemChange(item.id, e.target.value)}
                    />
                </div>

            ))}
            <div className="btn btn_add" onClick={addItemField}></div>
        </div>
    );
};

export default MultiInput;
