import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export const fetchTransaction = async (userToken, tx) => {
    try {
        const response = await axios.post(`${API_URL}/app/plan/save`, 
            {txId: tx},
            {
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'STM-API-SECURE-KEY': API_KEY,
                }
            });
        
        return response.data;
    } catch (error) {
        console.error('Error fetching bids:', error);
        throw error; // Handle error in component
    }
};

export const fetchPlan = async (userToken) => {
    try {
        const response = await axios.get(`${API_URL}/app/plan`,
            {
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'STM-API-SECURE-KEY': API_KEY,
                }
            });
        
        return response.data;
    } catch (error) {
        console.error('Error fetching user plan:', error);
        throw error; // Handle error in component
    }
};