import React, { useState, useEffect, useRef } from 'react';

const SystemInput = ({ onAddResponse, disabled }) => {
  const [userResponse, setUserResponse] = useState('');
  const textareaRef = useRef(null);
  const containerRef = useRef(null);
  const MAX_HEIGHT = 150; // Maximum height for the textarea

  const handleInputChange = (e) => {
    setUserResponse(e.target.value);
    autoGrowTextarea();
  };
  const handleBlurChange = (e) => {
    autoGrowTextarea();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    autoGrowTextarea();
    onAddResponse(convertToHtml(userResponse));
    setUserResponse('');
    setTimeout(autoGrowTextarea, 0); 
     // Reset the height after clearing the textarea
  };

  const autoGrowTextarea = () => {
    const textarea = textareaRef.current;
    const container = containerRef.current;
    if(textarea){
        textarea.style.height = '0';
        textarea.style.height = 'auto';
        if (textarea.scrollHeight > MAX_HEIGHT) {
        textarea.style.height = `${MAX_HEIGHT}px`;
        textarea.style.overflowY = 'auto'; // Enable vertical scroll if max height is reached
        } else if(textarea.scrollHeight <= 66 ) {
          textarea.style.height = `${textarea.scrollHeight - 14}px`;
          textarea.style.overflowY = 'hidden'; // Hide scrollbar
        } else {
        textarea.style.height = `${textarea.scrollHeight - 1}px`;
        textarea.style.overflowY = 'hidden'; // Hide scrollbar
        }
    }
  };

  useEffect(() => {
    autoGrowTextarea(); // Adjust height on mount
  }, []);

  // Convert newlines and spaces to HTML entities
  const convertToHtml = (text) => {
    const lines = text.split('\n');
    const htmlLines = lines.map(line => `<p>${line.trim()}</p>`).join('');
    return htmlLines;
};

  return (
    <div className="atom_input" ref={containerRef} style={{ position: 'relative', height: 'auto', maxHeight: `${MAX_HEIGHT}px` }}>
      <form  onSubmit={handleSubmit} style={{ position: 'relative' }}>
        <textarea
          ref={textareaRef}
          value={userResponse}
          onChange={handleInputChange}
          onBlur={handleBlurChange}
          placeholder="Your response"
          disabled={disabled}
          style={{
            resize: 'none', // Prevent manual resize
            overflow: 'hidden', // Hide scrollbar initially
            position: 'absolute',
            bottom: 0,
            width: '100%',
            fontSize: '15px',
            boxSizing: 'border-box',
            transition: 'all 0.2s ease-in-out', // Smooth transition
            whiteSpace: 'pre-wrap' // Preserve spacing and line breaks
          }}
        />
        {!disabled &&
          <button  type="submit">Submit</button>
        }
      </form>
    </div>
  );
};

export default SystemInput;
