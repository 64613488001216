import React, { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

const FormLogin = () => {
    const navigate = useNavigate();

    const [loginBtn, setLoginBtn] = useState('Login');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { handleLogin } = useContext(AuthContext); // Ensure this is correctly imported and used

    const onSubmit = async (event) => {
       
        event.preventDefault();
        setLoginBtn('Logging you in...')
        try {
            const response = await handleLogin(email, password);
            if (!response.success) {
                setErrors(response.errors);
                setLoginBtn('Login')
            } else {
                setLoginBtn('Redirecting...')
                setTimeout(() => {
                    navigate('/dashboard'); // Redirect to login page
                }, 1000);
                
            }
        } catch (error) {
            console.log('no bno');
            setLoginBtn('Login')
            setError('Login failed. Please try again.');
        }
    };

    return (
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text_black">Login</h3>
                <form onSubmit={onSubmit}>
                    {error && <div className="atom_error" data-aos="fade-left">{error}</div>}
                    <div className="fieldset">
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="input-email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Password</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            className="input-password"
                            placeholder="*******"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <p><a href="/forgotten">Forgotten Password</a></p>
                    </div>
                    <div className="fieldset_submit">
                        <input type="submit" name="submit" className="btn btn_green" value={loginBtn} />
                    </div>
                    <div className="fieldset">
                        <p>Need an Account? <a href="/signup">Sign Up</a> Here</p>
                    </div>
                    
                </form>
            </div>
        </div>
    );
};

export default FormLogin;
