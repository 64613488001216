import React, { useState, useEffect } from 'react';

// Images
import editIcon from "../../../img/icons/icon_edit.svg"
import fileIcon from "../../../img/icons/icon_files.svg"
import arrowIcon from "../../../img/icons/icon_chevron.svg";
import clockIcon from "../../../img/icons/icon_clock.svg";
import downloadIcon from "../../../img/icons/icon_download.svg";
import { Link, Navigate } from 'react-router-dom/dist';
import TimestampToDate from '../../../services/utilityService';



const CardDocument = ({ id, title, firstCreated, revisions, type }) => {
    const [isOpen, setIsOpen] = useState(false); // State to manage toggle status
    const [revisionsMessage, setRevisionsMessage] = useState('No Revisions'); 

    const toggleContent = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        if (revisions.length > 0) {
            setRevisionsMessage('Revisions')
        }
    }, [setRevisionsMessage, revisions]);

    return (
        <div className="col-xs-12 col-md-4">
            <div className="atom_cards atom_cards_document">
                
                <div className="title">
                    <p>{title}</p>
                  
                    {revisions.length > 0 ? (
                        <>
                                <div className="imgwrap">
                                    <img src={fileIcon} alt='Previous Versions' onClick={toggleContent}/>
                                </div>
                            
                                <div className="imgwrap">
                                <Link to={`/dashboard/${type}/edit/${id}/revision/${revisions.length}`} >
                                    <img src={editIcon} alt={title}/>
                                </Link>
                                </div>
                               
                        </>
                    ):(
                        <div className="imgwrap">
                            <Link to={`/dashboard/${type}/edit/${id}`} >
                                <img src={editIcon} alt={title}/>
                            </Link>
                        </div>
                    )}
                   
                </div>
                <p className="date"><img src={clockIcon} alt={title}/> <TimestampToDate timestamp={firstCreated} /></p>
                <div className="atom_toggle">
                    <span>{revisionsMessage}</span>
                    <div className={`atom_toggle_toggle ${isOpen ? 'open' : 'closed'}`}>
                        
                        {revisions.map((revision, index) => (
                            <div className="revision" key={index}>
                                <div className="timestamp">
                                    <TimestampToDate timestamp={revision.revision_date} />
                                </div>
                                <Link to={`/dashboard/${type}/edit/${id}/revision/${revision.revision_uuid}`} >
                                    <img src={editIcon} alt={title}/>
                                </Link>
                                <Link to={`/dashboard/${type}/download/${id}/revision/${revision.revision_uuid}`} >
                                    <img src={downloadIcon} alt={title}/>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                
            </div>
        </div>
       
    );
}

export default CardDocument;