import React, { useState } from 'react';


// Components
import Navigation from '../../components/global/nav_comp--main';
import AccountDropdown from '../../components/global/nav_comp--account';
import SpecificationBidInitial from '../../components/specifications/spec_comp--bid--initial';


const Bid_CreatePage = () => {
    const[disabled, setDisabled] = useState(false)
    return (
        <>
           <div className="container container_width--full bg_gradient_xlblue_hor">
                <div className="row layout_main">
                    <AccountDropdown/>
                    <div className="col-xs-12 col-sm-1 mobile_hide">
                        <Navigation mobile={false} active="bid"/>
                    </div>
                    <div className="col-xs-12 col-sm-11 ">
                        <div className="row atom_spec_area">
                            <SpecificationBidInitial />
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default Bid_CreatePage;