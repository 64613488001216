import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/authContext';
import { fetchPlan } from '../../../../services/stripeService';
import TimestampToDate from '../../../../services/utilityService';

// Images
import clockIcon from "../../../../img/icons/icon_clock.svg";
import arrowIcon from "../../../../img/icons/icon_chevron.svg";
import downloadIcon from "../../../../img/icons/icon_download.svg";
import { Link } from 'react-router-dom/dist';



const CurrentPlan = () => {
    const [planDetails, setPlanDetails] = useState(null);
    const { user,details } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);

    const toggleContent = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        console.log('details');
        console.log(details);
        if (details) {
            setPlanDetails(details.plan);
        }
    }, [details]);

    return (
        <>
        {planDetails && planDetails.length > 0 ? (
         <div className="atom_cards atom_cards_plan">
                <div className="title">
                    <small>Current Plan</small>
                    <p>{planDetails.product_description}</p>
                </div>
                <p className="date"><img src={clockIcon} alt={planDetails.product_description}/> Expires: <TimestampToDate timestamp={planDetails.plan_expires} /></p>
                <div className="atom_toggle">
                    <div className="atom_toggle_title" onClick={toggleContent}>
                        More Information
                        <img src={arrowIcon} alt="account" className={`${isOpen ? 'open' : 'closed'}`}/>
                    </div>
                    <div className={`atom_toggle_toggle ${isOpen ? 'open' : 'closed'}`}>
                        
                            <div className="revision">
                                <div className="timestamp">
                                    Created: 
                                </div>
                                <div className="right">
                                    <TimestampToDate timestamp={planDetails.plan_created} />
                                </div>
                            </div>
                            <div className="revision">
                                <div className="timestamp">
                                    Credits Remaining
                                </div>
                                <div className="right">
                                    {planDetails.purchasedCredits}
                                </div>
                            </div>
                            <div className="revision">
                                <div className="timestamp">
                                    Price
                                </div>
                                <div className="right">
                                    £{planDetails.amount / 100}
                                </div>
                            </div>
                               
                    </div>
                </div>
                
            </div>
            
        ) : (
            <div className="atom_cards atom_cards_plan">
                <div className="no_plans">
                    <h5>No Plans purchased</h5>
                    <Link to="/dashboard/profile/plan/upgrade" className="btn btn_green">Choose a Plan</Link>
                </div>
            </div>
        )}
        </>
    );
}

export default CurrentPlan;
