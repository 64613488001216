import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/authContext';
import { fetchPlan } from '../../../../services/stripeService';
import TimestampToDate from '../../../../services/utilityService';

// Images
import clockIcon from "../../../../img/icons/icon_clock.svg";
import arrowIcon from "../../../../img/icons/icon_chevron.svg";
import downloadIcon from "../../../../img/icons/icon_download.svg";
import { Link } from 'react-router-dom/dist';

const PreviousPlans = () => {
    const [planDetails, setPlanDetails] = useState(null);
    const { details } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(true);

    const toggleContent = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (details) {
            setPlanDetails(details.bills);
        }
    }, [details]);

    return (
        <>
            {planDetails && planDetails.length > 0 ? (
                <div className="atom_cards atom_cards_plan">
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Cost</th>
                                <th>Expired</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {planDetails.map((plan, index) => (
                                <tr key={index}>
                                    <td>{plan.plan_name}</td>
                                    <td>£{plan.plan_price / 100}</td>
                                    <td><TimestampToDate timestamp={plan.plan_expires} /></td>
                                    <td>{plan.plan_status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="atom_cards atom_cards_plan">
                    <div className="no_plans">
                        <h5>No Plans purchased</h5>
                        <Link to="/dashboard/profile/plan/upgrade" className="btn btn_green">Choose a Plan</Link>
                    </div>
                </div>
            )}
        </>
    );
}

export default PreviousPlans;
