import React, { useContext } from 'react';


// Components
import Navigation from '../../components/global/nav_comp--main';
import GlobalTitle from '../../components/global/header_comp--title';
import DashboardTenders from '../../components/dashboard/tenders';
import DashboardBids from '../../components/dashboard/bids';
import AccountDropdown from '../../components/global/nav_comp--account';
import { AuthContext, getUserDetails } from '../../../contexts/authContext';
import BgAnimation from '../../modules/general/animation_bg--circle';


const DashboardPage = () => {
    const {details} = useContext(AuthContext);
    return (
        <>
            <BgAnimation/>
            <div className="container container_width--full">
                <div className="row layout_main">
                    <AccountDropdown/>
                    <div className="col-xs-12 col-sm-1 mobile_hide">
                        <Navigation mobile={false} active="dashboard"/>
                    </div>
                    <div className="col-xs-12 col-sm-11 ">
                        <GlobalTitle title={`Welcome ${details.name}`}/>
                        <DashboardTenders/>
                        <DashboardBids/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardPage;