import React from 'react';



const GlobalSubTitle= ({ title }) => {
    return (
        <div className="container container_no--padding">
                <div className="row">
                    <div className={`col-xs-12 np`}>
                        <h4>{title}</h4>
                    </div>
                </div>
            </div>
    );
}

export default GlobalSubTitle;