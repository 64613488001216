import React, { useContext, useState } from 'react';
import { AuthContext } from '../contexts/authContext';
import { fetchBid, saveBid, generateHTMLforEdit, saveBidResponses, generateBidHTMLforEdit } from '../services/proposalService';



// ------- Bid Specification Hooks
// --
// -- Bid Specifican hooks

const useBid = (bid) => {
    const { user } = useContext(AuthContext); // Get Current User
    const [bidDetails, setBidDetails] = useState(null);
    const [loading, setLoading] = useState(true); 

    const getBid = async () => {
        if (!user || !bid) {
            setLoading(false); // Set loading to false if no user or bid
            return;
        }

        try {
            const details = await fetchBid(user.token, bid);
            setBidDetails(details.result.bids.revisions);
        } catch (error) {
            console.error('Error fetching bid details:', error);
        } finally {
            setLoading(false); // Ensure loading is set to false after fetch
        }
    };

    const createBid = async (responseUser, bid, name, revisionChanged, revision, fileContent) => {
        try {
            const response = await generateBidHTMLforEdit(user.token, responseUser, bid, name, revisionChanged, revision, fileContent);
            return response.result;
            // Handle the successful creation of the bid here
        } catch (error) {
            console.error('There was a problem with the create bid request:', error);
        }
    };

    const saveBid = async (responseUser, bid, name, revisionChanged, revision) => {
        try {
            const response = await saveBidResponses(user.token, responseUser, bid, name, revisionChanged, revision);
            return response.result;
            // Handle the successful creation of the bid here
        } catch (error) {
            console.error('There was a problem with the create bid request:', error);
        }
    };


    return {
        bidDetails,
        loading,
        getBid,
        createBid,
        saveBid
    };
};

export default useBid;
