import React, { useEffect, useRef } from 'react';

import loaderIcon from '../../../img/icons/icon_loading.svg';
import loadingIcon from '../../../img/icons/icon_loader.mp4';  // Update to .mp4

const GlobalLoading = ({ title, desc, box = true }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.playbackRate = 0.7;  // Set the playback rate to 0.5 to slow down the video
        }
    }, []);

    return (
        <div className={`atom_loading ${!box ? 'no-box' : ''}`} >
            <div className="inner" data-aos="zoom-in-up" data-aos-delay="500">
                <div className="video-container">
                    <video ref={videoRef} autoPlay muted loop>
                        <source src={loadingIcon} type="video/mp4" />  // Update to .mp4
                    </video>
                </div>
                {title &&
                    <h2>{title}</h2>
                }
                {desc &&
                    <p>{desc}</p>
                }
            </div>
        </div>
    );
}

export default GlobalLoading;
