import React, { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { validatePassword } from '../../services/validateService';
import { fetchPasswordReset } from '../../services/profileService';

const FormDetailsPassword = () => {
    const [saveBtn, setSaveBtn] = useState('Change Password');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const { user } = useContext(AuthContext); // Ensure this is correctly imported and used

    const onSubmit = async (event) => {
        event.preventDefault();
        setSaveBtn('Changing...');
        setError('');

        // Check if new password and confirm password match
        if (newPassword !== confirmPassword) {
            setError('New password and confirm password do not match.');
            setSaveBtn('Change Password');
            return;
        }

        // Validate new password
        if (!validatePassword(newPassword)) {
            setError('Password does not meet the required criteria.');
            setSaveBtn('Change Password');
            return;
        }

        try {
            const response = await fetchPasswordReset(user.token, oldPassword, newPassword);
            if (response) {
                setSaveBtn('Changed');
                setTimeout(() => {
                    setSaveBtn('Change Password');
                }, 1000);
            } else {
                setError(data.message || 'Something went wrong on save. Please try again.');
                setSaveBtn('Change Password');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('Something went wrong on save. Please try again.');
            setSaveBtn('Change Password');
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col-xs-12 col-md-8">
                    <p className="text_black">Set a New Password</p>
                    
                    {error && <div className="atom_error" data-aos="fade-left">{error}</div>}
                    
                    <div className="fieldset">
                        <label>Old Password</label>
                        <input
                            type="password"
                            name="old_password"
                            id="old_password"
                            placeholder="******"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                    </div>

                    <div className="fieldset">
                        <label>New Password</label>
                        <input
                            type="password"
                            name="new_password"
                            id="new_password"
                            placeholder="******"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>

                    <div className="fieldset">
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            name="confirm_password"
                            id="confirm_password"
                            placeholder="******"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    
                    <div className="fieldset_submit">
                        <input type="submit" name="submit" className="btn btn_green" value={saveBtn} />
                    </div>
                </div>
            </div>
        </form>
    );
};

export default FormDetailsPassword;
