import React from 'react';

const HTMLResponses = ({ aiResponses }) => {
  return (
    <div>
      {Object.keys(aiResponses).map((key, index) => (
        <div key={index} className="ai-response">
          <div dangerouslySetInnerHTML={{ __html: aiResponses[key].html }} />
        </div>
      ))}
    </div>
  );
};

export default HTMLResponses;