import React, { useState } from 'react';
import Editor from '@draft-js-plugins/editor';
import html2pdf from 'html2pdf.js';



const HTMLEditResponses = ({ originalContent }) => {
  /*const aiResponses = originalContent.ai[0].ai;

  // Initialize combinedHtml by joining all HTML content from aiResponses
  const [combinedHtml, setCombinedHtml] = useState(
    Object.keys(aiResponses).map(key => aiResponses[key].html).join('')
  );

  const handleSave = (editorState) => {
    // Handle saving editor content if needed
    console.log('Editor content saved:', editorState.getCurrentContent());
  };

  const handleDownloadPDF = async () => {
    const headerTemplate = `
      <div style="text-align: center; font-size: 10px;">
        Header Content
      </div>
    `;
  
    const footerTemplate = `
      <div style="text-align: center; font-size: 10px;">
        Page <span class="pageNumber"></span> of <span class="totalPages"></span>
      </div>
    `;
  
    const element = document.createElement('div');
    element.innerHTML = `
      <style>
       
        body {
          font-family: Arial, sans-serif;
          font-size: 12px;
        }
      </style>
      ${combinedHtml}
    `;
    document.body.appendChild(element);
  
    try {
      await html2pdf()
        .from(element)
        .set({
          margin: 1,
          filename: originalContent.ai[0].static.staticTenderTitle + '.pdf',
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
          pagebreak: { mode: ['avoid-all'] },
          header: [headerTemplate],
          footer: [footerTemplate],
        })
        .save();
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  
    document.body.removeChild(element);
  };

  return (
    <div className="atom_document_edit">
      <div className="atom_document_area">
        <div className="ai-response paper">
          <Editor
            editorState={EditorState.createEmpty()} // Provide initial EditorState if needed
            onChange={handleSave} // Handle editor changes
            plugins={plugins} // Provide plugins array
          />
        </div>
        <button onClick={handleDownloadPDF}>Download as PDF</button>
      </div>
    </div>
  );*/
};


export default HTMLEditResponses;
