import React from 'react';



const GlobalTitle= ({ title }) => {
    return (
        <h3 className="global_title">{title}</h3>
    );
}

export default GlobalTitle;