import React, { useContext, useState } from 'react';
import { AuthContext } from '../contexts/authContext';
import { saveTenderResponses, saveInitialTender } from '../services/proposalService';
import starterQuestion from '../json/questions/question_1.json';



// ------- Tender Creation Specification Hooks
// --
// -- Tender Specifican hooks

const useCreateTender = (formMaxQuestions) => {
    const { user } = useContext(AuthContext); // Get Current User

    const [btnTextSave, setBtnTextSave] = useState('Set message');

    const [booleanRevisionChanged, setBooleanRevisionChanged] = useState(false);
    const [booleanResponseDisabled, setBooleanResponseDisabled] = useState(true);

    const [textFolderName, setTextFolderName] = useState('');
    const [textFolderSubName, setTextFolderSubName] = useState('');
    const [textSubSubName, setTextSubSubName] = useState('');

    const [questionCurrent, setQuestionCurrent] = useState(starterQuestion);
    const [questionNumberCurrent, setQuestionNumberCurrent] = useState(1);
    const [questionNumberLast, setQuestionNumberLast] = useState(formMaxQuestions);
    const [questionNumberReturn, setQuestionNumberReturn] = useState(null);

    const [collectUserResponses, setCollectUserResponses] = useState([]);
    

// ----------------
// -- HANDLE - ADD RESPONSE -  adds each response to array
// ----------------
    const handleAddQuestionResponse = async (response) => {
        const userResponse = typeof response === 'string' ? response.toLowerCase() : '';
        
        setCollectUserResponses(prevResponses => {
            const responseIndex = prevResponses.findIndex(res => res.questionNumberCurrent === questionNumberCurrent);
            let updatedResponses;
            let category = textFolderName;
            let subCategory = textFolderSubName;
            let subSubCategory = textSubSubName;

            if(questionNumberCurrent === 1){
                category = userResponse;
            }
            if(questionNumberCurrent === 2){
                subCategory = userResponse;
            }
            if(questionNumberCurrent === 3){
                subSubCategory = userResponse;
                setTextSubSubName(subSubCategory);
            }
    
            if (responseIndex !== -1) {
                updatedResponses = [...prevResponses];
                updatedResponses[responseIndex] = {
                    ...updatedResponses[responseIndex],
                    response: userResponse,
                    class: !userResponse ? 'empty' : ''
                };
            } else {
                updatedResponses = [
                    ...prevResponses,
                    {
                        key:"QuestionNo_"+questionNumberCurrent,
                        category: category,
                        subCategory: subCategory,
                        subSubCategory: subSubCategory,
                        questionNumber: questionNumberCurrent,
                        question: questionCurrent.aiQuestion,
                        info: questionCurrent.info,
                        autoResponse: questionCurrent.aiHints,
                        response: userResponse,
                        class: !userResponse ? 'empty' : ''
                    }
                ];
            }
            return updatedResponses;
        });
    
        const nextQuestionNumber = questionNumberCurrent + 1;
    
        if (questionNumberCurrent < formMaxQuestions) {
            try {
                if (questionNumberCurrent === 1) {
                    setTextFolderName(userResponse);
                    await functionLoadNextQuestion(userResponse, nextQuestionNumber, formMaxQuestions);
                } else if (questionNumberCurrent === 2) {
                    setTextFolderSubName(userResponse);
                    await functionLoadNextQuestion(textFolderName, nextQuestionNumber, formMaxQuestions);
                } else {
                    await functionLoadNextQuestion(textFolderName, nextQuestionNumber, formMaxQuestions);
                }
            } catch (error) {
                console.error("Error loading next question:", error);
            }
        } else if (questionNumberCurrent === formMaxQuestions) { 
            // Handle the final question case
            try {
                await functionLoadNextQuestion(textFolderName, nextQuestionNumber, formMaxQuestions);
            } catch (error) {
                console.error("Error loading final question:", error);
            }
        } else if (questionNumberCurrent > formMaxQuestions) {

            setQuestionNumberLast('0');
            setQuestionNumberCurrent('0');
        }
    };
    

// ----------------
// -- HANDLE - EDIT RESPONSE -  Edits each response within array
// ----------------   

    const handleEditQuestionResponse = async (questionNumberToEdit) => {
        try {
            const responseToEditIndex = collectUserResponses.findIndex(res => res.questionNumberCurrent === questionNumberToEdit);
    
            if (responseToEditIndex === -1) {
                console.error(`Response not found for question number ${questionNumberToEdit}`);
                return;
            }
    
            let questionPath;
            if (textFolderName && questionNumberToEdit === 2) {
                questionPath = `${textFolderName}/question_${questionNumberToEdit}.json`;
            } else {
                questionPath = `question_${questionNumberToEdit}.json`;
            }
    
            const editQuestion = await import(`../json/questions/${questionPath}`);
    
            setQuestionCurrent(editQuestion);
            setQuestionNumberCurrent(questionNumberToEdit);
            setQuestionNumberReturn(currentQuestion); // Save the current question to return to
            setBooleanRevisionChanged(true);
            setResponseDisabled(false);
    
        } catch (error) {
            console.error("Error editing response:", error);
        }
    };

// ----------------
// -- HANDLE - Remove Question
// ---------------- 
const handleRemoveQuestionResponse = async () => {};

// ----------------
// -- HANDLE - Question Skip
// ---------------- 
const handleSkipQuestionResponse = async () => {};

// ----------------
// -- HANDLE - Create Tender - saves initial responses before generating questions in AI
// ---------------- 

const handleTenderCreation = async (title, collectUserResponses) => {
    try {
        const data = await saveInitialTender(user.token, title, collectUserResponses);
        return data;
    } catch (error) {
        console.error('Error saving tender:', error);
    }
};

// ----------------
// -- SAVE - EDIT RESPONSE -  Edits each response within array
// ----------------  

    const saveEditedQuestionResponse = async (response) => {
        const userResponse = response.toLowerCase();

        setCollectUserResponses(prevResponses => {
            const updatedResponses = prevResponses.map(res => {
                if (res.questionNumberCurrent === questionNumberCurrent) {
                    return {
                        ...res,
                        response: userResponse,
                        class: userResponse.trim() === '' ? 'empty' : ''  // Update class based on response
                    };
                } else {
                    return res;
                }
            });
    
            return updatedResponses;
        });


        if (lastQuestionNumber < formMaxQuestions) {
            await functionLoadNextQuestion(textFolderName, nextQuestionNumber, formMaxQuestions);
        } else {
        }
    };

// ----------------
// -- FUNCTION - Loads the Next question - initial questions come from JSON files
// ---------------- 
const functionLoadNextQuestion = async (textFolderName, nextQuestionNumber, formMaxQuestions) => {
    let questionPath;

    if (textFolderName && nextQuestionNumber === 2) {
        questionPath = `${textFolderName}/question_${nextQuestionNumber}.json`;
    } else {
        questionPath = `question_${nextQuestionNumber}.json`;
    }



        if(nextQuestionNumber <= formMaxQuestions) {
            try {
                const nextQuestion = await import(`../json/questions/${questionPath}`);

                setQuestionCurrent(nextQuestion);
                if(nextQuestion.responses.length > 0){
                    setBooleanResponseDisabled(true);
                }else{
                    setBooleanResponseDisabled(false);
                }

                setQuestionNumberLast(nextQuestionNumber);
                setQuestionNumberCurrent(nextQuestionNumber);
            } catch (error) {
                console.error(`Error loading question ${nextQuestionNumber} from ${questionPath}:`, error);
                
                setQuestionNumberLast('0');
                setQuestionNumberCurrent('0');
            }
        }else{
                setBooleanResponseDisabled(true);
                setQuestionNumberLast('0');
                setQuestionNumberCurrent('0');
        }
};



    /*const tenderCreation = async (collectUserResponses, tender, name, revisionChanged, revision) => {
        try {
            const response = await saveTenderResponses(user.token, collectUserResponses, tender, name, revisionChanged, revision);
            return response.result;
            // Handle the successful creation of the tender here
        } catch (error) {
            console.error('There was a problem with the create tender request:', error);
        }
    };*/




    return {
        btnTextSave,
        booleanRevisionChanged,
        booleanResponseDisabled,
        questionNumberCurrent,
        questionNumberLast,
        questionCurrent,
        textFolderName,
        textFolderSubName,
        collectUserResponses,
        setBooleanResponseDisabled,
        handleTenderCreation,
        handleAddQuestionResponse,
        handleEditQuestionResponse,
        handleRemoveQuestionResponse,
        handleSkipQuestionResponse,
        saveEditedQuestionResponse
    };
};

export default useCreateTender;
