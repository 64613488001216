import React, { useState } from 'react';


// Components
import Navigation from '../../components/global/nav_comp--main';
import AccountDropdown from '../../components/global/nav_comp--account';
import BgAnimation from '../../modules/general/animation_bg--circle';
import SpecificationTenderInitial from '../../components/specifications/spec_comp--tender--initial';


const Tender_CreatePage = () => {
    return (
        <>
           <BgAnimation/>
            <div className="container container_width--full">
                <div className="row layout_main">
                    <AccountDropdown/>
                    <div className="col-xs-12 col-sm-1 mobile_hide">
                        <Navigation mobile={false} active="tender"/>
                    </div>
                    <div className="col-xs-12 col-sm-11 ">
                        <div className="row atom_spec_area">
                            <SpecificationTenderInitial/>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default Tender_CreatePage;