import React, { useContext } from 'react';

// Components
import CardPrice from '../global/carc_comp--price';


const PricingModule = () => {
    return (
        <>
            <div className="container np layout_pricing_mobile_inner">
                <div className="row np">
                    <CardPrice
                        title="Individual"
                        subtitle="Pay per Document"
                        price="from £399"
                        item1="Tender Specification generation at £399"
                        item2="Bid proposal generation at £449"
                        item3="Save up to 3 versions until you finalise your work. Unlimited updates before saving."
                        url1="https://buy.stripe.com/test_4gw9EyesU7GQdtC5kl"
                        cta1="Specification Package"
                        url2="https://buy.stripe.com/test_4gw9EyesU7GQdtC5kl"
                        cta2="Bid Package"
                        color="light"
                    />
                    <CardPrice
                        title="Periodic"
                        subtitle="Subscription for 3 Credits"
                        price="from £999"
                        item1="Generate 3 unique specifications or bid proposals"
                        item2="Save unlimited versions of your document"
                        item3="Credits valid for up to 18 months from purchase"
                        url1="https://buy.stripe.com/test_6oEg2WfwY9OY4X6dQS"
                        cta1="Specification Package"
                        url2="https://buy.stripe.com/test_3cs2c6ckMaT20GQdQT"
                        cta2="Bid Package"
                        color="dark"
                    />
                    <CardPrice
                        title="Frequent"
                        subtitle="Subscription for Unlimited Credits"
                        price="from £599pm"
                        item1="Generate unlimited Unique specifications or bid proposals"
                        item2="Add up to 5 company members to your account"
                        item3="Save unlimited versions of your document"
                        url1="https://buy.stripe.com/test_28odUO3Og6CM89i3cg"
                        cta1="Specification Subscription"
                        url2="https://buy.stripe.com/test_eVa03YdoQ4uEahq7sx"
                        cta2="Bid Subscription"
                        color="light"
                    />
                </div>
            </div>
        </>
    );
}

export default PricingModule;
