import React, { useState, useEffect, useContext } from 'react';
import GlobalSubTitle from '../global/header_comp--subtitle';
import CardCreate from '../global/card_comp--create';
import { AuthContext } from '../../../contexts/authContext';
import { fetchTenders } from '../../../services/proposalService';

// Images
import editIcon from "../../../img/icons/icon_edit.svg";
import plusIcon from "../../../img/icons/icon_plus.svg";
import CardDocument from '../global/card_comp--document';
import { Link } from 'react-router-dom';

const DashboardTenders = () => {
    const [tenders, setTenders] = useState([]); // Initialize tenders as an empty array
    const [isOpen, setIsOpen] = useState(true); // State to manage toggle status
    const { user, details } = useContext(AuthContext); // Access user context

    useEffect(() => {
        const getTenders = async () => {
            try {
                const data = await fetchTenders(user.token);
                if(data){
                    console.log(data.result.tender.tenders);
                    setTenders(data.result.tender.tenders);
                }
            } catch (error) {
                console.error('Error fetching tenders:', error);
            }
        };
        getTenders();
        
    }, [user.token]); // Update useEffect dependency

    const toggleContent = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="container component_toggle" data-aos="fade-up">
            <div className="row">
                <div className={`col-xs-12 toggle_section ${isOpen ? 'open' : 'closed'}`} >
                    <GlobalSubTitle title="My Tenders"/>
                    <a href="/dashboard/tender/create">
                        <div className="icon_edit">Add</div>
                    </a>
                    <div className="icon_number">{tenders?.length ? tenders.length : 0}</div>
                    <img src={plusIcon} alt="toggle icon" onClick={toggleContent}/>
                </div>
            </div>
            <div className={`row toggle_content ${isOpen ? 'open' : 'closed'}`}>
                <CardCreate title="Create a New Tender Specification" url="/dashboard/tender/create" />
                {tenders?.map(tender => (
                    <CardDocument key={tender.tender_uuid} id={tender.tender_uuid} title={tender.name} firstCreated={tender.first_created} revisions={tender.revisions} type="tender"/>
                ))}
            </div>
        </div>
    );
}


export default DashboardTenders;
