import React, { useEffect, useContext, useState } from 'react';


// Layout Components
import GlobalParagraph from '../global/type_comp--paragraph';
import SystemAnswer from '../../modules/system/system_mod--answer';
import Tender_FormTitle from '../../modules/specifications/forms/tender_mod--form-title';
import SpecQuestionArea from './layouts/spec_sub--question-area';

// -- Hooks
import useCreateTender from '../../../hooks/useCreateTender';

// -- Globals
import GlobalLoading from '../global/modal_comp--loading';

const SpecificationTenderInitial = ({tender = null, disabled = false}) => {
    const [actionModalOpen, setActionModalOpen] = useState(false);

    const [formTitle, setFormTitle] = useState('');
    const [formMaxQuestions, setFormMaxQuestions] = useState(4);

    const {
        btnTextSave,
        booleanRevisionChanged,
        booleanResponseDisabled,
        questionCurrent,
        questionNumberCurrent,
        questionNumberLast,
        textFolderName,
        textFolderSubName,
        collectUserResponses,
        setBooleanResponseDisabled,
        handleTenderCreation,
        handleAddQuestionResponse,
        handleEditQuestionResponse,
        handleSkipQuestionResponse,
        handleRemoveQuestionResponse,
        saveEditedQuestionResponse

    } = useCreateTender(formMaxQuestions);



    const handleSave = async () => {
        try{
            setActionModalOpen(true);
            const getTenderQuestions = await handleTenderCreation(formTitle, collectUserResponses);
            if(getTenderQuestions.success){
                console.log(getTenderQuestions);
                window.location.href=`/dashboard/tender/edit/${getTenderQuestions.result.tender}/revision/1`
            }
        }
        catch(error){
            console.log(error);
        }

    };

    const handleClear = async () => {
        window.location.href = ""

    };

    return (
        <>
        
        {actionModalOpen ?(
            <GlobalLoading title="We are just getting your questions!" desc="This may take a few minutes so please do not leave the page"/>
        ):('')}
       
        <div className="atom_half_wrapper">
            <div className="atom_half flex-end">
                <div className="atom_cards atom_cards_spec mobile_hide" data-aos="fade-up" data-aos-delay="100">
                    <GlobalParagraph 
                        subtitle="Lets Start by asking a couple of Quick Questions" 
                        text="Start by Entering a title for your Tender Specification and telling us what you need it for" 
                        col="12" 
                        padding="np" 
                    />
                </div> 

                <div className="atom_spec_title_area" data-aos="fade-up" data-aos-delay="900">
                    <Tender_FormTitle 
                        setTitle={setFormTitle} 
                        title={formTitle}
                    />
                </div>
                <SpecQuestionArea
                    questionNumberCurrent={questionNumberCurrent}
                    questionNumberLast={questionNumberLast}
                    questionCurrent={questionCurrent}
                    handleAddQuestionResponse={handleAddQuestionResponse}
                    saveEditedQuestionResponse={saveEditedQuestionResponse}
                    handleSkipQuestionResponse={handleSkipQuestionResponse}
                    handleRemoveQuestionResponse={handleRemoveQuestionResponse}
                    textFolderName={textFolderName}
                    textFolderSubName={textFolderSubName}
                    formMaxQuestions={formMaxQuestions}
                    formTitle={formTitle} 
                    handleSave={handleSave}
                    handleClear={handleClear}
                    finalQuestionHeader='Looks Great to far!'
                    finalQuestionText='Save your Initial Responses and Get your taliored questions'
                    finalBtn={true}
                    booleanResponseDisabled={booleanResponseDisabled}
                />

            </div>
            <div className="atom_half">
                <div className="atom_flexible_responses" data-aos="fade-up" data-aos-delay="2100"> 
                        {
                            
                            collectUserResponses.length > 0 ? (
                                collectUserResponses.map((response, index) => (
                                    <React.Fragment key={index}>
                                        <SystemAnswer 
                                            question={response.question}
                                            response={response.response} 
                                            questionNumberCurrent={response.questionNumberCurrent} 
                                            onEditResponse={handleEditQuestionResponse} 
                                            isEmpty={response.class}
                                        />
                                    </React.Fragment>
                                ))
                            ) : (
                                <h4>Let's Get Started</h4>
                            )
                        }
                </div>
            </div>
        </div>
        
        </>
    );
};

export default SpecificationTenderInitial;
