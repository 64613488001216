import React, { useEffect, useContext, useState } from 'react';


// Layout Components
import GlobalParagraph from '../global/type_comp--paragraph';
import SystemAnswer from '../../modules/system/system_mod--answer';
import Bid_FormTitle from '../../modules/specifications/forms/bid_mod--form-title';
import SpecQuestionArea from './layouts/spec_sub--question-area';

// -- Hooks
import useBid from '../../../hooks/useBid';
import useEditBid from '../../../hooks/useEditBid';

// -- Globals
import GlobalLoading from '../global/modal_comp--loading';

import useResponseScroll from '../../../hooks/useResponseScroll';
import SpecProgressBar from '../../modules/specifications/actions/progress_mod--bar';
import SystemMiniAnswer from '../../modules/system/system_mod--mini-answer';
import HTMLEditResponses from '../../modules/templates/tender/mod_html--edit';
import PreviewBid from '../../modules/specifications/forms/preview_mod--bid';

const SpecificationBidEdit = ({bid = null, revision = null, disabled = false}) => {
    const [actionModalOpen, setActionModalOpen] = useState(false);
    

    const [formTitle, setFormTitle] = useState('');
    const [formMaxQuestions, setFormMaxQuestions] = useState(99);
    const [loading, setPageLoading] = useState(true);
    const [fileContent, setFileContent] = useState('');

    const [textLoaderTitle, setTextLoaderTitle] = useState('We are now generating your Bid!');
    const [textLoaderDesc, setTextLoaderDesc] = useState('This may take a few minutes so please do not leave the page')

    const {
        actionPreviewOpen,
        actionEditOpen,
        btnTextSave,
        btnTextRender,
        booleanRevisionChanged,
        booleanResponseDisabled,
        questionNumberCurrent,
        questionNumberLast,
        questionCurrent,
        textFolderName,
        textFolderSubName,
        collectUserResponses,
        collectAIQuestions,
        collectBidGenerated,
        functionProgressBar,
        setActionPreviewOpen,
        setActionEditOpen,
        setBtnTextRender,
        setBtnTextSave,
        setBooleanResponseDisabled,
        setCollectUserResponses,
        setCollectAIQuestions,
        setCollectBidGenerated,
        setQuestionNumberCurrent,
        setFunctionProgressBar,
        handleBidCreation,
        handleAddQuestionResponse,
        handleEditQuestionResponse,
        handleSkipQuestionResponse,
        handleRemoveQuestionResponse,
        handleInitialQuestion,
        saveEditedQuestionResponse, 
        functionLoadNextQuestion,
        handlePreviewClose,
        handlePreviewOpen,
        handleEditBid,
        handleEditClose
    } = useEditBid(formMaxQuestions);

    const {
        containerRefResponses,
        handleScrollUp,
        handleScrollDown,
        handleResponseScroll
    } = useResponseScroll();

    const {
        bidDetails,
        getBid,
        createBid,
        saveBid
    } = useBid(bid);

    useEffect(() => {

        // --- Get the saved bid Bid initially
        if (bid && !bidDetails) {
            getBid();
        }

        // --- Pull the AI Questions
        if(bidDetails && collectAIQuestions.length <= 0){
            const aiQuestionsParsed = JSON.parse(bidDetails.ai_questions).ai;
            setFileContent(bidDetails.original_pdf);
            setFormTitle(bidDetails.name)
            setFormMaxQuestions(aiQuestionsParsed.length) // includes original 4 first asked
            console.log(bidDetails.ai_questions)
            setCollectAIQuestions(bidDetails.ai_questions); 

        }

        const revisionUpdate = parseInt(revision, 10) - 1;

        // --- Pull any Saved Responses already associated with this bid / revision
        if(collectUserResponses.length <= 0 && (bidDetails && bidDetails.revisions[revisionUpdate]?.revision_json)){
            const aiQuestionsParsed = JSON.parse(bidDetails.ai_questions).ai;
            const revisionJson = JSON.parse(bidDetails.revisions[revisionUpdate]?.revision_json);
            const progressPercentage = (revisionJson.length / (aiQuestionsParsed.length)) * 100;
            setCollectUserResponses(revisionJson);
            setQuestionNumberCurrent(revisionJson.length + 1);
            setFunctionProgressBar(progressPercentage);
            if(progressPercentage >= '99'){
                setQuestionNumberCurrent(0);
            }else{
                setQuestionNumberCurrent(revisionJson.length + 1);
            }
            
        }

        // ---  if Starting question empty, force the question
        if (!questionCurrent && collectAIQuestions.length >= 1) {
            const load = handleInitialQuestion()
            if(load){
                setPageLoading(false);
            }
        }
       
    }, [bid, getBid, bidDetails, revision, collectUserResponses, formMaxQuestions, questionCurrent]);

    const handleRenderBid = async () => {
        try {
            setBtnTextRender('Processing...'); // Update button message
            // -- Processing Modal
            setActionModalOpen(true);
            setActionPreviewOpen(false);

            const bidAIResponse = await createBid(collectUserResponses, bid, bidDetails.name, booleanRevisionChanged, revision, fileContent);
            console.log(bidAIResponse);
            setCollectBidGenerated(bidAIResponse);
            setBtnTextRender('Saved!'); // Update button message after success
            setActionModalOpen(false);

            setTimeout(() => {
                setBtnTextRender('Document Generated');
            }, 3000);
            setActionPreviewOpen(true);
    
        } catch (error) {
            console.error('Error:', error);
            // Handle error state or display error message
            setBtnTextRender('Try Again!');
        }
    };

    const handleSaveBid = async () => {
        try {
     
            setBtnTextSave('Processing...'); // Update button message

            await saveBid(collectUserResponses, bid, bidDetails.name, booleanRevisionChanged, revisionNo);
           
            setBtnTextSave('Saved!'); // Update button message after success
            setTimeout(() => {
                setBtnTextSave('Save Version');
            }, 3000);
    
        } catch (error) {
            console.error('Error:', error);
            // Handle error state or display error message
            setBtnTextSave('Try Again!');
        }
    };

    const handleClear = async () => {
        window.location.href = ""
    };

    useEffect(() => {
        
        if (containerRefResponses.current) {
            containerRefResponses.current.scrollTop = containerRefResponses.current.scrollHeight;
        }
    }, [collectUserResponses, containerRefResponses]);

    if (loading) {
        return <GlobalLoading box={false} />;
    }

    return (
        <>
        {actionModalOpen ?(
            <GlobalLoading title={textLoaderTitle} desc={textLoaderDesc}/>
        ):('')}

        {actionPreviewOpen && (
            
            <div className={`atom_tender_preview ${actionPreviewOpen ? 'open' : ''}`}>
                <div className="atom_tender_close" onClick={handlePreviewClose}>X</div>
                <PreviewBid originalContent={collectBidGenerated} handleEditBid={handleEditBid} />
            </div>
        )}

        {actionEditOpen && (
            <div className={`atom_tender_preview ${actionEditOpen ? 'open' : ''}`}>
                <div className="atom_tender_close" onClick={handleEditClose}>X</div>
                <HTMLEditResponses originalContent={collectBidGenerated} />
            </div>
        )}
       
        <div className="atom_half_wrapper">
            <div className="atom_half flex-end">
                <div className="atom_spec_title_area" data-aos="fade-up" data-aos-delay="100">
                    <Bid_FormTitle 
                        setTitle={setFormTitle} 
                        title={formTitle}
                    />
                </div>
                <div className="atom_spec top_bar" data-aos="fade-up" data-aos-delay="900">
                    <div className="atom_mini_responses">
                            <div className="inner">
                                <div className="atom_spec_actions">
                                    <SpecProgressBar
                                        progressBar={functionProgressBar} 
                                        maxQuestions={formMaxQuestions} 
                                    />
                                </div>
                                <div className="buttons">
                                    {questionNumberCurrent == 0 ? (
                                        <button title="Generate Bid" className="btn btn_coral generate icon_only" onClick={handleRenderBid} ></button>
                                    ):('')}
                                    <button title="Save Your Answers" className="btn btn_green save icon_only" onClick={handleSaveBid} ></button>
                                </div>
                            </div>
                        </div>
                </div>
                <SpecQuestionArea
                    questionNumberCurrent={questionNumberCurrent}
                    questionNumberLast={questionNumberLast}
                    questionCurrent={questionCurrent}
                    handleAddQuestionResponse={handleAddQuestionResponse}
                    handleSkipQuestionResponse={handleSkipQuestionResponse}
                    handleRemoveQuestionResponse={handleRemoveQuestionResponse}
                    saveEditedQuestionResponse={saveEditedQuestionResponse}
                    textFolderName={textFolderName}
                    textFolderSubName={textFolderSubName}
                    formMaxQuestions={formMaxQuestions}
                    formTitle={formTitle} 
                    handleSave={handleSaveBid}
                    handleClear={handleClear}
                    handleRender={handleRenderBid}
                    finalQuestionHeader='Great Job!'
                    finalQuestionText='Are you ready to generate your document? '
                    finalBtn={false}
                    btnTextRender={btnTextRender}
                    btnTextSave={btnTextSave}
                    booleanResponseDisabled={booleanResponseDisabled}
                    />
            </div>
            <div className="atom_half">
                {collectUserResponses.length > 0 ? (
                    <div className="action_bar">
                        <div className="scroll-buttons">
                            <button onClick={handleScrollDown}>Scroll Up</button>
                            <button onClick={handleScrollUp} >Scroll Down</button>
                        </div>
                        <div className="btns">
                            {collectUserResponses.map((response, index) => (
                            <React.Fragment key={index}>
                                <SystemMiniAnswer
                                    questionNumberCurrent={response.questionNumber}
                                    onEditResponse={handleEditQuestionResponse}
                                    isEmpty={response.class}
                                />
                            </React.Fragment>
                            ))}
                        </div>
                    </div>
                ):('')}
                
                <div className={`atom_flexible_responses ${collectUserResponses.length <= 0 ? 'white_bg' : ''}`} 
                     ref={containerRefResponses}
                     onScroll={handleResponseScroll}> 
                        {collectUserResponses.length > 0 ? (
                                collectUserResponses.map((response, index) => (
                                    <React.Fragment key={index}>
                                        <SystemAnswer 
                                            question={response.question}
                                            response={response.response} 
                                            questionNumberCurrent={response.questionNumber} 
                                            onEditResponse={handleEditQuestionResponse} 
                                            isEmpty={response.class}
                                        />
                                    </React.Fragment>
                                ))
                            ) : (
                                <h4><GlobalLoading box={false}/></h4>
                            )
                        }
                </div>
            </div>
        </div>
        
        </>
    );
};

export default SpecificationBidEdit;
