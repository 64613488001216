import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';


const FormForgotten= () => {
    const { handleForgotten } = useContext(AuthContext); // Ensure this is correctly imported and used
    const [loginBtn, setLoginBtn] = useState('Reset Password');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const onSubmit = async (event) => {
       
        event.preventDefault();
        setLoginBtn('Resetting...')
        try {
            const response = await handleForgotten(email);
            if (!response.success) {
                setErrors(response.errors);
                setLoginBtn('Reset Password')
            } else {
                setLoginBtn('Email Sent!')
                setTimeout(() => {
                    setLoginBtn('Reset Password')
                }, 5000);
                
            }
        } catch (error) {
            console.log(error);
            setLoginBtn('Reset Password')
            setError('Reset Failed. Please try again.');
        }
    };
    return (
            <div className="row">
                <div className="col-xs-12">
                   <h3 className="text_black">Forgotten Password</h3>
                   <form onSubmit={onSubmit}>
                   {error && <div className="atom_error" data-aos="fade-left">{error}</div>}
                        <div className="fieldset">
                            <label>Email</label>
                            <input 
                                type="email" 
                                name="email" 
                                id="email" 
                                className="input-email" 
                                placeholder="Enter your email" 
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        
                        <div className="fieldset_submit">
                            <input type="submit" name="submit"  className="btn btn_green" value={loginBtn}/>
                        </div>
                        <div className="fieldset">
                            <p>Go back to <a href="/login">Login</a></p>
                        </div>
                    </form>
                </div>
            </div>

    );
}

export default FormForgotten;