import React, { useContext } from 'react';


// Components
import Navigation from '../../components/global/nav_comp--main';
import AccountDropdown from '../../components/global/nav_comp--account';
import { AuthContext, getUserDetails } from '../../../contexts/authContext';


import GlobalSubTitle from '../../components/global/header_comp--subtitle';
import GlobalParagraph from '../../components/global/type_comp--paragraph';
import PricingModule from '../../components/profile/profile_comp--pricing';
import BgAnimation from '../../modules/general/animation_bg--circle';


const Profile_PlanUpgradePage = () => {
    return (
        <>
            <BgAnimation/>
            <div className="container container_width--full layout_profile">
                <div className="row layout_main">
                    <AccountDropdown/>
                    <div className="col-xs-12 col-sm-1 mobile_hide">
                        <Navigation mobile={false} active="plan"/>
                    </div>
                    <div className="col-xs-12 col-sm-11 layout_pricing_mobile">
                        <GlobalSubTitle title="Pricing plans"/>
                        <GlobalParagraph subtitle ="Unlocking Value, Crafting Success" text="Designed with you in mind. We understand that every business has unique needs and preferences when it comes to pricing. That's why we've crafted plans that put you in control and ensure you get the best value for your money. " padding="np" col="10"/>
                        <PricingModule/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile_PlanUpgradePage;