import React, {useState} from 'react';

// Images
import infoIcon from "../../../img/icons/icon_info.svg";


const SystemQuestionAI = ({ questionCurrent, questionNumberCurrent, onSelectResponse, onSkipResponse, onRemoveResponse, isBid }) => {
    const [showInfo, setShowInfo] = useState(false);

    const handleInfoClick = () => {
        setShowInfo(!showInfo);
    };
    
    return (
        <>
        <div className="atom_question" data-aos="fade-up">
            <div className="atom_question_inner">
                <small>{questionCurrent?.required ? questionCurrent?.required : 'optional'}</small>
                <p>
                    <b>Q{questionNumberCurrent}:  </b> 
                    {questionCurrent.aiQuestion}
                    {questionCurrent.info && <img src={infoIcon} alt="info"  onClick={handleInfoClick}/>}
                </p>
                {showInfo && (
                    <p className="info">
                        {questionCurrent.info}
                    </p>
                )}
                {questionCurrent.responses && (
                <>
                        {questionCurrent.responses.map((response, index) => (
                            <div key={index} className="btn btn_white" onClick={() => onSelectResponse(response)}>
                                {response}
                            </div>
                        ))}
                </>
                )}
                {isBid && (
                    <>
                    <div className="buttons">
                        <div className="btn btn_transparent white" onClick={onSkipResponse}>
                            Skip & Generate 
                        </div>
                        <div></div>
                        <div className="btn btn_transparent white" onClick={onRemoveResponse}>
                            Remove 
                        </div>
                    </div>
                    </>
                )}
                {questionNumberCurrent >= 5 && !isBid && (
                    <>
                    <div className="buttons">
                        <div className="btn btn_transparent white" onClick={onSkipResponse}>
                            Skip & Generate 
                        </div>
                        <div></div>
                        <div className="btn btn_transparent white" onClick={onRemoveResponse}>
                            Remove 
                        </div>
                    </div>
                    </>
                )}
            </div>
        </div>
        {questionCurrent.aiHint  && (
                <div className="atom_question_hint" data-aos="zoom-in-up" data-aos-delay="1000">
                    <b>Hint:</b> {questionCurrent.aiHints ? questionCurrent.aiHints : questionCurrent.aiHint}
                </div>
        )}
        {questionCurrent.aiHints  && (
                <div className="atom_question_hint" data-aos="zoom-in-up" data-aos-delay="1000">
                    <b>Hint:</b> {questionCurrent.aiHints ? questionCurrent.aiHints : questionCurrent.aiHint}
                </div>
        )}
                
        </>
    );
}

export default SystemQuestionAI;
