import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export const fetchPasswordReset = async (userToken, oldPassword, newPassword) => {
    try {
        const response = await axios.post(`${API_URL}/app/user/password/reset`, 
            {
                "oldpassword": oldPassword,
                "newpassword": newPassword
            },
            {
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'STM-API-SECURE-KEY': API_KEY,
                }
            });
        
        return response.data;
    } catch (error) {
        console.error('Error Changing Password:', error);
        throw error; // Handle error in component
    }
};

export const postCompanySave = async (userToken, data) => {
    try {
        const response = await axios.post(`${API_URL}/app/users/company/save`, 
            {
                data
            },
            {
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'STM-API-SECURE-KEY': API_KEY,
                }
            });
        
        return response.data;
    } catch (error) {
        console.error('Error saving company:', error);
        throw error; // Handle error in component
    }
};

