import axios from "axios";

// proposalService.js
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;


// ----------------
// -- FETCH -- ALL
// ----------------

export const fetchTenders = async (userToken) => {
    try {
        const response = await axios.get(`${API_URL}/app/tenders`, {
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'STM-API-SECURE-KEY': API_KEY,
            }
        });
        
        return response.data;
    } catch (error) {
        console.error('Error fetching tenders:', error);
        throw error; // Handle error in component
    }
};

export const fetchBids = async (userToken) => {
    try {
        const response = await axios.get(`${API_URL}/app/bids`, {
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'STM-API-SECURE-KEY': API_KEY,
            }
        });
        
        return response.data;
    } catch (error) {
        console.error('Error fetching bids:', error);
        throw error; // Handle error in component
    }
};

// ----------------
// -- FETCH -- Single
// ----------------

export const fetchTender = async (userToken, tender, revision) => {
    try {
        const response = await axios.post(`${API_URL}/app/tender`, 
            {tender_uuid: tender, revision_no: revision},
            {
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'STM-API-SECURE-KEY': API_KEY,
                }
            });
        console.log(response)
        return response.data;
    } catch (error) {
        console.error('Error fetching tender:', error);
        throw error; // Handle error in component
    }
};

export const fetchBid = async (userToken, bid) => {
    try {
        const response = await axios.post(`${API_URL}/app/bid`, 
            {bid_uuid: bid},
            {
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'STM-API-SECURE-KEY': API_KEY,
                }
            });
        
        return response.data;
    } catch (error) {
        console.error('Error fetching bids:', error);
        throw error; // Handle error in component
    }
};


// ----------------
// -- POST / SAVE
// ----------------


export const saveInitialTender = async (userToken, title, responseUser) => {
    try {
        console.log(responseUser);
        const response = await axios.post(`${API_URL}/app/tenders`, 
            {
                name: title,
                responses: responseUser,
            },
            {
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'STM-API-SECURE-KEY': API_KEY,
            }
        });
        
        return response.data;
    } catch (error) {
        console.error('Error saving tenders:', error);
        throw error; // Handle error in component
    }
};
export const saveTenderResponses = async (userToken, responseUser, tender, name, revisionChanged,revision) => {
    try {
        const response = await axios.post(`${API_URL}/app/tender/responses`, 
            {
                responses: responseUser,
                tender_uuid: tender,
                name: name,
                revisionChanged: revisionChanged,
                revision_no: revision,
                ai:false

            },
            {
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'STM-API-SECURE-KEY': API_KEY,
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error saving bids:', error);
        throw error; // Handle error in component
    }
};

export const saveBidResponses = async (userToken, responseUser, bid, name, revisionChanged,revision) => {
    try {
        const response = await axios.post(`${API_URL}/app/bid/responses`, 
            {
                responses: responseUser,
                bid_uuid: bid,
                name: name,
                revisionChanged: revisionChanged,
                revision_no: revision,
                ai:false

            },
            {
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'STM-API-SECURE-KEY': API_KEY,
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error saving bids:', error);
        throw error; // Handle error in component
    }
};



// ----------------
// -- Create
// ----------------

export const generateHTMLforEdit = async (userToken, responseUser, tender, name, revisionChanged,revision) => {
    try {
        console.log(responseUser);
        const response = await axios.post(`${API_URL}/app/tender/responses`, 
            {
                responses: responseUser,
                tender_uuid: tender,
                name: name,
                revisionChanged: revisionChanged,
                revision_no: revision,
                ai:true,

            },
            {
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'STM-API-SECURE-KEY': API_KEY,
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error saving bids:', error);
        throw error; // Handle error in component
    }
};

export const generateBidHTMLforEdit = async (userToken, responseUser, bid, name, revisionChanged,revision, fileContent) => {
    try {
        alert();
        const response = await axios.post(`${API_URL}/app/bid/responses`, 
            {
                responses: responseUser,
                bid_uuid: bid,
                name: name,
                revisionChanged: revisionChanged,
                revision_no: revision,
                ai:true,
                fileContent: fileContent ? fileContent : ''

            },
            {
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'STM-API-SECURE-KEY': API_KEY,
                'Content-Type': 'application/json',
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error saving bids:', error);
        throw error; // Handle error in component
    }
};




// ----------------
// -- UPLOAD PDF
// ----------------
export const uploadTender = async (userToken, fileName, fileContent) => {
    try {
        const response = await axios.post(`${API_URL}/app/upload/tender`, 
            {
                fileName: fileName,
                fileContent: fileContent
            },
            {
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'STM-API-SECURE-KEY': API_KEY,
                }
            });
        
        return response.data;
    } catch (error) {
        console.error('Error fetching bids:', error);
        throw error; // Handle error in component
    }
};

// ----------------
// -- READ TENDER FOR BID QUESTIONS
// ----------------
export const readTender = async (userToken, fileName) => {
    try {
        const response = await axios.post(`${API_URL}/app/read/tender`, 
            {
                fileName: fileName,
            },
            {
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'STM-API-SECURE-KEY': API_KEY,
                }
            });
        
        return response.data;
    } catch (error) {
        console.error('Error fetching bids:', error);
        throw error; // Handle error in component
    }
};