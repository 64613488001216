import React, { useState, useContext } from 'react';
import arrowIcon from "../../../img/icons/icon_chevron.svg";
import { AuthContext } from '../../../contexts/authContext';
import { Link } from 'react-router-dom/dist';
import Navigation from './nav_comp--main';

const AccountDropdown = () => {
    const [isMobileOpen, setMobileIsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false); // State to manage toggle status
    const { handleLogout, user, details } = useContext(AuthContext); // Access handleLogout from AuthContext

    const toggleContent = () => {
        setIsOpen(!isOpen);
    }

    const toggleMobileContent = () => {
        setMobileIsOpen(!isMobileOpen);
    }

    const handleLogoutClick = () => {
        // Call handleLogout function from AuthContext
        handleLogout();
        // Optionally, you can navigate to the login page or perform other actions after logout
    }


    return (
        <>
        <Navigation open={isMobileOpen} mobile={true}/>
        <div className="atom_account">
            <div className="menu" onClick={toggleMobileContent} />
            

            
            <div className="atom_account_title" onClick={toggleContent}>
                Account
                <img src={arrowIcon} alt="account" className={`${isOpen ? 'open' : 'closed'}`}/>
            </div>
       
            <div className={`atom_account_toggle ${isOpen ? 'open' : 'closed'}`}>
                <div className={`atom_account_content`} >
                    <div className="avatar_section mobile_hide">
                        <div className="avatar">
                            <div className="inner"></div>
                        </div>
                        {details && <div className="name">{details.name}</div>}
                    </div>
                    <div className="link_section">
                        <Link to="/dashboard/profile/settings"><div className="link-settings">Account Settings</div></Link>
                        <div className="link-logout" onClick={handleLogoutClick}>Logout</div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default AccountDropdown;
