import React, { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/authContext';

const FormDetailsUser = () => {
    const {details} = useContext(AuthContext);
    console.log(details);
    const [saveBtn, setSaveBtn] = useState('Save User Details');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { handleLogin } = useContext(AuthContext); // Ensure this is correctly imported and used

    const onSubmit = async (event) => {
       
        event.preventDefault();
        setSaveBtn('Saving...')
        try {
            const response = await handleLogin(email, password);
            if (response.success) {
                setErrors(response.errors);
                setSaveBtn('Saved!');
                setTimeout(() => {
                    setSaveBtn('Save User Details');
                }, 1000);

            } 
        } catch (error) {
            console.log('no bno');
            setSaveBtn('Save User Details')
            setError('Something went wrong on save. Please try again.');
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <div className="row">
                
                <div className="col-xs-12 col-md-6">
                    <p className="text_black">Personal Details</p>
                    
                        {error && <div className="atom_error" data-aos="fade-left">{error}</div>}
                        <div className="fieldset">
                            <label>Name:</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Name"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="fieldset">
                            <label>Street Address</label>
                            <input
                                type="text"
                                name="address"
                                id="address"
                                placeholder="Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                    
                </div>
                <div className="col-xs-12 col-md-6">
                    Accreditations
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <div className="fieldset_submit">
                        <input type="submit" name="submit" className="btn btn_green" value={saveBtn} />
                    </div>
                </div>
                
            </div>
        </form>
    );
};

export default FormDetailsUser;
