import React, { useContext, useState } from 'react';


// Components
import Navigation from '../../components/global/nav_comp--main';
import AccountDropdown from '../../components/global/nav_comp--account';
import GlobalParagraph from '../../components/global/type_comp--paragraph';
import ProfileNavigation from '../../components/profile/profile_comp--nav';
import FormDetailsCompany from '../../forms/form_details--company';
import FormDetailsUser from '../../forms/form_details--user';
import BgAnimation from '../../modules/general/animation_bg--circle';


const Profile_SettingsPage = () => {
    const [isCompany, setCompany] = useState(true);

    const handleFormDisplay = (formType) => () => {
        if(formType === 'company'){
            setCompany(true);
        }else{
            setCompany(false);
        }
    };
    return (
        <>
            <BgAnimation/>
            <div className="container container_width--full layout_profile">
                <div className="row layout_main">
                    <AccountDropdown/>
                    <div className="col-xs-12 col-sm-1 mobile_hide">
                        <Navigation mobile={false} active="settings"/>
                    </div>
                    <div className="col-xs-12 col-sm-11 ">
                        <GlobalParagraph subtitle="Edit Profile"/>
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-md-3">
                                    <ProfileNavigation active="settings"/>
                                </div>
                                <div className="col-xs-12 col-md-9 overflow">
                                    <div className="buttons">
                                        <div className={`btn ${!isCompany ? "btn_green" : "btn_white"}`} onClick={handleFormDisplay('personal')}>Personal</div>
                                        <div className={`btn ${isCompany ? "btn_green" : "btn_white"}`} onClick={handleFormDisplay('company')}>Company</div>
                                    </div>
                                    {isCompany ? (
                                        <FormDetailsCompany/>
                                    ):(
                                        <FormDetailsUser/>
                                    )}

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
}

export default Profile_SettingsPage;