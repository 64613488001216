import React, { useState } from 'react';
import { validateEmail, validatePassword } from '../../services/validateService';
import { signup } from '../../services/authService';
import { generatePassword } from '../../services/utilityService';
import { useNavigate } from 'react-router-dom';

const FormSignup = () => {
    const {history} = useNavigate();
    const [ctaBtn, setCtaBtn] = useState('Sign up');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [fullname, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handlePassword = async (event) => {
        const password = await generatePassword();
        setPassword(password);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setCtaBtn('Signing up...')

        // Validate email and password
        if (!validateEmail(email)) {
            setCtaBtn('Sign up')
            setError('Please enter a valid email address.');
            return;
        }
        if (!validatePassword(password)) {
            setCtaBtn('Sign up')
            setError('Password must be at least 8 characters long, contain at least 1 capital letter, and at least 1 number.');
            return;
        }

        try {
            const response = await signup(fullname, email, password);
            if (response.success) {
                setCtaBtn('Done');
                setSuccess('You have signed up! Please check your email for confirmation. You will be now redirected to the login page');
                setTimeout(() => {
                    navigate('/login'); // Redirect to login page
                }, 3000);
            } else {
                setError('Uh Oh!');
                setCtaBtn('Sign up');
            }
        } catch (error) {
            setCtaBtn('Sign up')
            setError('There was an error on Sign Up. Please try again');
        }
    };

    

    return (
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text_black">Sign Up</h3>
                {error && <div className="atom_error" data-aos="fade-left">{error}</div>}
                {success && <div className="atom_success" data-aos="fade-left">{success}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="fieldset">
                        <label>Full Name</label>
                        <input type="text" name="fullname" id="fullname" className="input-user" placeholder="Enter your name" value={fullname} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="fieldset">
                        <label>Email</label>
                        <input type="email" name="email" id="email" className="input-email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="fieldset">
                        <label>Password</label>
                        <input type="type" name="password" id="password" className="input-password" placeholder="*******" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <p className="ft-small" onClick={handlePassword}>Suggest a Password</p>
                    </div>
                    <div className="fieldset_submit">
                        <input type="submit" name="submit" className="btn btn_green" value={ctaBtn} />
                    </div>
                    <div className="fieldset">
                        <p>Already have and account? <a href="/login">Login</a> Here</p>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FormSignup;
