import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import '../src/css/styles.css';
import AOS from 'aos';


import { Navigate } from 'react-router-dom';
import { AuthContext, AuthProvider } from './contexts/authContext';
import SignUpPage from './templates/pages/auth/auth_page--signup';
import LoginPage from './templates/pages/auth/auth_page--login';
import ForgottenPage from './templates/pages/auth/auth_page--forgotten';
import DashboardPage from './templates/pages/dashboard/dashboard_page';

import { PrivateRoute } from './templates/components/privateRoute';
import Tender_CreatePage from './templates/pages/tender/tender_page--create';
import Tender_EditPage from './templates/pages/tender/tender_page--edit';
import Bid_EditPage from './templates/pages/bid/bid_page--edit';
import Bid_CreatePage from './templates/pages/bid/bid_page--create';
import Profile_SettingsPage from './templates/pages/profile/profile_page--settings';
import Profile_PlanUpgradePage from './templates/pages/profile/profile_page--upgrade';
import Profile_BillsPage from './templates/pages/profile/profile_page--bills';
import Profile_ReferPage from './templates/pages/profile/profile_page--refer';
import Profile_DeletePage from './templates/pages/profile/profile_page--delete';
import Profile_PasswordPage from './templates/pages/profile/profile_page--password';
import Profile_PlanUpgradeConfirmPage from './templates/pages/profile/profile_page--confirm';
import Profile_PlanPage from './templates/pages/profile/profile_page--plan';
//import MDocument from './templates/modules/specifications/forms/test';
import ResetPage from './templates/pages/auth/auth_page--reset';
import NotFoundPage from './templates/pages/global/404_page--notfound';



const App = () => {
    useEffect(() => {
      AOS.init({});
    }, []);
    return (
      <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<SignUpPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotten" element={<ForgottenPage />} />
          <Route path="/reset-password/token/:token/id/:id" element={<ResetPage />} />
          
          <Route path="/dashboard" element={<PrivateRoute element={<DashboardPage />} />} />
          
          <Route path="/dashboard/profile/settings" element={<PrivateRoute element={<Profile_SettingsPage />} />} />
          <Route path="/dashboard/profile/settings/refer" element={<PrivateRoute element={<Profile_ReferPage />} />} />
          <Route path="/dashboard/profile/settings/delete" element={<PrivateRoute element={<Profile_DeletePage />} />} />
          <Route path="/dashboard/profile/settings/password" element={<PrivateRoute element={<Profile_PasswordPage/>} />} />
          <Route path="/dashboard/profile/plan" element={<PrivateRoute element={<Profile_PlanPage />} />} />
          <Route path="/dashboard/profile/plan/upgrade" element={<PrivateRoute element={<Profile_PlanUpgradePage />} />} />
          <Route path="/dashboard/profile/plan/confirm/:tx" element={<PrivateRoute element={<Profile_PlanUpgradeConfirmPage />} />} />
          <Route path="/dashboard/profile/bills" element={<PrivateRoute element={<Profile_BillsPage/>} />} />
          
          <Route path="/dashboard/tender/create" element={<PrivateRoute element={<Tender_CreatePage />} />} />
          <Route path="/dashboard/tender/edit/:id" element={<PrivateRoute element={<Tender_EditPage />} />} />
          <Route path="/dashboard/tender/edit/:id/revision/:revision" element={<PrivateRoute element={<Tender_EditPage />} />} />
          
          <Route path="/dashboard/bid/create" element={<PrivateRoute element={<Bid_CreatePage />} />} />
          <Route path="/dashboard/bid/edit/:id" element={<PrivateRoute element={<Bid_EditPage />} />} />
          <Route path="/dashboard/bid/edit/:id/revision/:revision" element={<PrivateRoute element={<Bid_EditPage />} />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </AuthProvider>
    );
};

export default App;
