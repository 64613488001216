import React from 'react';

const Bid_FormTitle = ({ setTitle, title }) => {

    return (
        <input
            type="text"
            name="title"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter title"
            className="atom_spec_title"
        />
    );
};

export default Bid_FormTitle;
