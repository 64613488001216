import { useState, useEffect, useContext, useRef } from 'react';

// ------- General Proposal Hooks
// --
// -- Combine hooks used by both tender and bids

const useResponseScroll = () => {
    const containerRefResponses = useRef(null);
    const [responseScrolling, setResponseScrolling] = useState(false);

    useEffect(() => {
        if (!responseScrolling && containerRefResponses.current) {
            containerRefResponses.current.scrollTo({
                top: containerRefResponses.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [responseScrolling]);

    const handleScrollUp = () => {
        if (containerRefResponses.current) {
            containerRefResponses.current.scrollBy({
                top: -100,
                behavior: 'smooth',
            });
            setResponseScrolling(true);
        }
    };

    const handleScrollDown = () => {
        if (containerRefResponses.current) {
            containerRefResponses.current.scrollBy({
                top: 100,
                behavior: 'smooth',
            });
            setResponseScrolling(true);
        }
    };

    const handleResponseScroll = () => {
        if (containerRefResponses.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRefResponses.current;
            if (scrollTop + clientHeight < scrollHeight) {
                setResponseScrolling(true);
            } else {
                setResponseScrolling(false);
            }
        }
    };

    return {
        containerRefResponses,
        handleScrollUp,
        handleScrollDown,
        handleResponseScroll,
    };
};

export default useResponseScroll;
