import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../contexts/authContext';
import { saveBidResponses, saveInitialBid } from '../services/proposalService';
import starterQuestion from '../json/questions/question_1.json';



// ------- Bid Edit Specification Hooks
// --
// -- Bid Specifican hooks

const useEditBid = (formMaxQuestions) => {
    const { user } = useContext(AuthContext); // Get Current User
    const [actionPreviewOpen, setActionPreviewOpen] = useState(false);
    const [actionEditOpen, setActionEditOpen] = useState(false);

    const [btnTextSave, setBtnTextSave] = useState('Save Responses');
    const [btnTextRender, setBtnTextRender] = useState('Generate Document');

    const [booleanRevisionChanged, setBooleanRevisionChanged] = useState(false);
    const [booleanResponseDisabled, setBooleanResponseDisabled] = useState(false);

    const [textFolderName, setTextFolderName] = useState('');
    const [textFolderSubName, setTextFolderSubName] = useState('');
    const [textSubSubName, setTextSubSubName] = useState('');

    const [questionCurrent, setQuestionCurrent] = useState();
    const [questionNumberCurrent, setQuestionNumberCurrent] = useState(1);
    const [questionNumberLast, setQuestionNumberLast] = useState(formMaxQuestions);
    const [questionNumberReturn, setQuestionNumberReturn] = useState(0);

    const [collectUserResponses, setCollectUserResponses] = useState([]);
    const [collectAIQuestions, setCollectAIQuestions] = useState([]);
    const [collectBidGenerated, setCollectBidGenerated] = useState(null);

    const [functionProgressBar, setFunctionProgressBar] = useState(0);

    

// ----------------
// -- HANDLE - ADD RESPONSE -  adds each response to array
// ----------------
const handleAddQuestionResponse = async (response) => {
        const userResponse = typeof response === 'string' ? response.toLowerCase() : '';
        
        setCollectUserResponses(prevResponses => {
            const responseIndex = prevResponses.findIndex(res => res.questionNumberCurrent === questionNumberCurrent);
            let updatedResponses;
            let category = textFolderName;
            let subCategory = textFolderSubName;
            let subSubCategory = textSubSubName;

            if(questionNumberCurrent === 1){
                category = userResponse;
            }
            if(questionNumberCurrent === 2){
                subCategory = userResponse;
            }
            if(questionNumberCurrent === 3){
                subSubCategory = userResponse;
                setTextSubSubName(subSubCategory);
            }
    
            if (responseIndex !== -1) {
                updatedResponses = [...prevResponses];
                updatedResponses[responseIndex] = {
                    ...updatedResponses[responseIndex],
                    response: userResponse,
                    class: !userResponse ? 'empty' : ''
                };
            } else {
                updatedResponses = [
                    ...prevResponses,
                    {
                        key:"QuestionNo_"+questionNumberCurrent,
                        category: category,
                        subCategory: subCategory,
                        subSubCategory: subSubCategory,
                        questionNumber: questionNumberCurrent,
                        question: questionCurrent.aiQuestion,
                        info: questionCurrent.info,
                        hints: questionCurrent.aiHints ? questionCurrent.aiHints : questionCurrent.aiHint ,
                        response: userResponse,
                        class: !userResponse ? 'empty' : ''
                    }
                ];
            }

            const progressPercentage = (updatedResponses.length / formMaxQuestions) * 100;
            setFunctionProgressBar(progressPercentage);
            setBooleanRevisionChanged(true);

            return updatedResponses;
        });
    
        const nextQuestionNumber = questionNumberCurrent + 1;
    
        if (questionNumberCurrent < formMaxQuestions) {
            try {
                if (questionNumberCurrent === 1) {
                    setTextFolderName(userResponse);
                    await functionLoadNextQuestion(nextQuestionNumber, formMaxQuestions);
                } else if (questionNumberCurrent === 2) {
                    setTextFolderSubName(userResponse);
                    await functionLoadNextQuestion( nextQuestionNumber, formMaxQuestions);
                } else {
                    await functionLoadNextQuestion( nextQuestionNumber, formMaxQuestions);
                }
            } catch (error) {
                console.error("Error loading next question:", error);
            }
        } else if (questionNumberCurrent === formMaxQuestions) { 
            // Handle the final question case
            try {
                await functionLoadNextQuestion( nextQuestionNumber, formMaxQuestions);
            } catch (error) {
                console.error("Error loading final question:", error);
            }
        } else if (questionNumberCurrent > formMaxQuestions) {

            setQuestionNumberLast('0');
            setQuestionNumberCurrent('0');
        }
    };
    

// ----------------
// -- HANDLE - EDIT RESPONSE -  Edits each response within array
// ----------------   

const handleEditQuestionResponse = async (questionNumberToEdit) => {
        try {
            const responseToEditIndex = collectUserResponses.findIndex(res => res.questionNumber === questionNumberToEdit);

            if (responseToEditIndex === -1) {
                console.error(`Response not found for question number ${questionNumberToEdit}`);
                return;
            }
    
            const parsedAiResponses = JSON.parse(collectAIQuestions).ai;        
            const editQuestion = parsedAiResponses.find(q => q.questionNumber === questionNumberToEdit);
    
            setQuestionCurrent(editQuestion);
            setQuestionNumberCurrent(questionNumberToEdit);
            setQuestionNumberReturn(questionCurrent); // Save the current question to return to
            setBooleanRevisionChanged(true);
    
        } catch (error) {
            console.error("Error editing response:", error);
        }
    };
// ----------------
// -- HANDLE - Question Skip
// ---------------- 
const handleSkipQuestionResponse = async () => {
        const skippedResponse = {
            key:"QuestionNo_"+questionNumberCurrent,
            questionNumber: questionNumberCurrent,
            question: questionCurrent.aiQuestion,
            info: questionCurrent.info,
            hints: questionCurrent.aiHint,
            response: '',
            class: 'empty'
        };

        setCollectUserResponses(prevResponses => {
            const updatedResponses = [
                ...prevResponses,
                skippedResponse
            ];

            const progressPercentage = (updatedResponses.length / formMaxQuestions) * 100;
            setFunctionProgressBar(progressPercentage);
            setBooleanRevisionChanged(true);

            return updatedResponses;
        });

        if (questionNumberCurrent <= formMaxQuestions) {
            const nextQuestionNumber = questionNumberCurrent + 1;
            try {
                await functionLoadNextQuestion( nextQuestionNumber, formMaxQuestions);
            } catch (error) {
                console.error("Error loading next question:", error);
            }
        }
    };

// ----------------
// -- HANDLE - Remove Question
// ---------------- 
const handleRemoveQuestionResponse = async () => {
    
    if (questionNumberCurrent <= formMaxQuestions) {
        const nextQuestionNumber = questionNumberCurrent + 1;
        try {
            await functionLoadNextQuestion( nextQuestionNumber, formMaxQuestions);
        } catch (error) {
            console.error("Error loading next question:", error);
        }
    } 
};

// ----------------
// -- HANDLE - Create Bid - saves initial responses before generating questions in AI
// ---------------- 

const handleBidCreation = async (title, collectUserResponses) => {};

// ----------------
// -- HANDLE - Initial Question Load
// ---------------- 

const handleInitialQuestion = async () => {
    try {
        await functionLoadNextQuestion(questionNumberCurrent, formMaxQuestions);

    } catch (error) {
        console.error('Error saving bid:', error);
    }
};

// ----------------
// -- SAVE - EDIT RESPONSE -  Edits each response within array
// ----------------  

const saveEditedQuestionResponse = async (response) => {
        const userResponse = response.toLowerCase();
        alert(questionNumberCurrent);

        setCollectUserResponses(prevResponses => {
            const updatedResponses = prevResponses.map(res => {
                if (res.questionNumber === questionNumberCurrent) {
                    return {
                        ...res,
                        response: userResponse,
                        class: userResponse.trim() === '' ? 'empty' : ''  // Update class based on response
                    };
                } else {
                    return res;
                }
            });
    
            return updatedResponses;
        });
        const nextQuestionNumber = questionNumberCurrent + 1;
        if (questionNumberLast < formMaxQuestions) {
            await functionLoadNextQuestion(nextQuestionNumber, formMaxQuestions);
        } else {
        }
    };

// ----------------
// -- FUNCTION - Loads the Next question - initial questions come from JSON files
// ---------------- 
const functionLoadNextQuestion = async (nextQuestionNumber, formMaxQuestions) => {
    let questionPath;
    console.log('next question number:' + nextQuestionNumber)
    console.log('max questions:' + formMaxQuestions)
        if(nextQuestionNumber <= formMaxQuestions) {
            setBooleanResponseDisabled(false);
            try {
                const parsedAiResponses = JSON.parse(collectAIQuestions).ai;   
                const nextQuestion = parsedAiResponses.find(q => q.questionNumber === nextQuestionNumber);
               
                setQuestionCurrent(nextQuestion);

                setQuestionNumberLast(nextQuestionNumber);
                setQuestionNumberCurrent(nextQuestionNumber);
            } catch (error) {
                console.error(`Error loading question ${nextQuestionNumber} from ${questionPath}:`, error);

            }
        }else{
                setQuestionNumberLast('0');
                setQuestionNumberCurrent('0');
                setBooleanResponseDisabled(true);
        }
};


// ----------------
// -- ACTION BUTTONS - calls to actions
// ---------------- 
const handlePreviewClose = () => {
    setActionPreviewOpen(false);
};

const handlePreviewOpen = () => {
    setActionPreviewOpen(true);
};

const handleEditBid = () => {
   
    setActionEditOpen(true);
    setActionPreviewOpen(false);
};

const handleEditClose = () => {
    setActionEditOpen(false);
};



    /*const bidCreation = async (collectUserResponses, bid, name, revisionChanged, revision) => {
        try {
            const response = await saveBidResponses(user.token, collectUserResponses, bid, name, revisionChanged, revision);
            return response.result;
            // Handle the successful creation of the bid here
        } catch (error) {
            console.error('There was a problem with the create bid request:', error);
        }
    };*/

    return {
        actionPreviewOpen,
        actionEditOpen,
        btnTextSave,
        btnTextRender,
        booleanRevisionChanged,
        booleanResponseDisabled,
        questionNumberCurrent,
        questionNumberLast,
        questionCurrent,
        textFolderName,
        textFolderSubName,
        collectUserResponses,
        collectAIQuestions,
        collectBidGenerated,
        functionProgressBar,
        setActionPreviewOpen,
        setActionEditOpen,
        setBtnTextRender,
        setBtnTextSave,
        setBooleanResponseDisabled,
        setCollectUserResponses,
        setCollectAIQuestions,
        setCollectBidGenerated,
        setQuestionNumberCurrent,
        setFunctionProgressBar,
        handleBidCreation,
        handleAddQuestionResponse,
        handleEditQuestionResponse,
        handleSkipQuestionResponse,
        handleRemoveQuestionResponse,
        handleInitialQuestion,
        saveEditedQuestionResponse, 
        functionLoadNextQuestion,
        handlePreviewClose,
        handlePreviewOpen,
        handleEditBid,
        handleEditClose
    };
};

export default useEditBid;
