import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import Navigation from '../../components/global/nav_comp--main';
import AccountDropdown from '../../components/global/nav_comp--account';
import GlobalParagraph from '../../components/global/type_comp--paragraph';
import { fetchTransaction } from '../../../services/stripeService';
import { AuthContext } from '../../../contexts/authContext';

//images 
import loaderIcon from "../../../img/icons/icon_loading.svg";
import { Route } from 'react-router-dom/dist';
import BgAnimation from '../../modules/general/animation_bg--circle';

const Profile_PlanUpgradeConfirmPage = () => {
    const navigate = useNavigate();

    const { tx } = useParams();
    const [planDetails, setPlanDetails] = useState(null);
    const [error, setError] = useState(null);
    const { user} = useContext(AuthContext);

    useEffect(() => {
        const getTransaction = async () => {
            if(tx){
                // record and save transaction
                try {
                    const data = await fetchTransaction(user.token, tx);
                    if(data){
                        setPlanDetails(data);
                        Route.push('/dashboard/profile/plan');
                    }
                } catch (error) {
                    console.error('Error fetching user transaction:', error);
                    navigate('/dashboard/profile/plan');
                }
            }
            
        };
        getTransaction();
       
    }, [tx]);

    return (
        <>
            <BgAnimation/>
            <div className="container container_width--full bg_gradient_xlblue_hor">
                <div className="row layout_main">
                    <AccountDropdown/>
                    <div className="col-xs-12 col-sm-1 mobile_hide">
                        <Navigation mobile={false} active="plan"/>
                    </div>
                    <div className="col-xs-12 col-sm-11 atom_loading ">
                        <img src={loaderIcon} />
                        <h2>Thank you for your purchase</h2>
                        <p>You will shortly be directed to your Plan page</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile_PlanUpgradeConfirmPage;
