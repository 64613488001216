import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../contexts/authContext';
import TenderUploadForBid from '../global/file_uploader--pdf';
import GlobalLoading from '../global/modal_comp--loading';
import { uploadTender } from '../../../services/proposalService';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import mammoth from 'mammoth';

// Set the workerSrc property for pdfjs-dist
//https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs`;

// Function to extract text from PDF
const extractTextFromPDF = async (file) => {
  const arrayBuffer = await file.arrayBuffer();
  const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
  let text = '';

  for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
    const page = await pdf.getPage(pageNum);
    const content = await page.getTextContent();
    const pageText = content.items.map(item => item.str).join(' ');
    text += pageText + ' ';
  }

  return text;
};

// Function to extract text from DOCX
const extractTextFromDocx = async (file) => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const { value } = await mammoth.extractRawText({ arrayBuffer });
    return value;
  } catch (error) {
    console.error('Error extracting text from DOCX file:', error);
    throw new Error('Failed to extract text from DOCX file.');
  }
};

// Function to handle file extraction
const extractTextFromDoc = async (file) => {
  // DOC files are binary, different extraction method needed (e.g., using `docxtemplater`, `jszip`, or a server-side library)
  // For now, let's assume DOC files are not supported.
  throw new Error('DOC files are not supported for text extraction.');
};

const SpecificationBidInitial = () => {
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [actionBtnChange, setActionBtnChange] = useState('Upload');
  const [fileName, setFileName] = useState('Find your File');
  const [fileContent, setFileContent] = useState('');
  const { user } = useContext(AuthContext); // Get Current User
  const [error, setError] = useState('')

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);

    let content = '';
    try {
      if (file.type === 'application/pdf') {
        // Extract text from PDF
        content = await extractTextFromPDF(file);
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        // Extract text from DOCX
        content = await extractTextFromDocx(file);
      } else if (file.type === 'application/msword') {
        // Handle DOC files differently or notify user
        throw new Error('DOC files are not supported. Please use .docx or pdf');
      } else {
        throw new Error('Unsupported file type.');
      }
      setFileContent(content);
      setActionBtnChange('Upload');
    } catch (error) {
      console.error('Error processing file:', error);
      alert(error.message); // Notify user of the error
    }
  };

  const handleUpload = async () => {
    if (!fileContent) return;

    setActionModalOpen(true);
    setActionBtnChange('Uploading');

    try {
      const getBidQuestions = await uploadTender(user.token, fileName, fileContent);
      console.log(getBidQuestions);
      if (getBidQuestions.success) {
        window.location.href = `/dashboard/bid/edit/${getBidQuestions.result.bid}/revision/1`;
      } else {
        setError(getBidQuestions.message);
      }
    } catch (error) {
      console.error('Error uploading tender:', error);
    } finally {
      setActionModalOpen(false);
      setActionBtnChange('Upload');
    }
  };

  return (
    <>
      {actionModalOpen ? (
        <GlobalLoading title="We are just getting your questions!" desc="This may take a few minutes so please do not leave the page" />
      ) : null}

      <div className="atom_half flex-end">
        <div className="atom_spec">
          
          <TenderUploadForBid
            actionBtnChange={actionBtnChange}
            handleFileChange={handleFileChange}
            handleUpload={handleUpload}
            fileName={fileName}
            error={error}
          />
        </div>
      </div>
      <div className="atom_half">
        {/* Additional content */}
      </div>
    </>
  );
};

export default SpecificationBidInitial;
