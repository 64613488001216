import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom'; // or useNavigate if using React Router v6+
import { validatePassword } from '../../services/validateService'; // Ensure this import is correct
import { AuthContext } from '../../contexts/authContext';

const FormReset = () => {
    const { handleReset } = useContext(AuthContext); // Ensure this is correctly imported and used
    const { token, id } = useParams(); // Get token and user ID from the URL
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetBtn, setResetBtn] = useState('Reset Password');
    const [error, setError] = useState('');

    const onSubmit = async (event) => {
        event.preventDefault();
        setError('');

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        if (!validatePassword(password)) {
            setError('Password does not meet the required criteria.');
            return;
        }

        setResetBtn('Resetting...');

        try {
            const response = await handleReset(token, id, password);
            if (!response.success) {
                setError(response.errors);
                setResetBtn('Reset Password');
            } else {
                setResetBtn('Password Reset!');
                setTimeout(() => {
                    setResetBtn('Reset Password');
                    window.location.href= '/login'; // Redirect to login page after reset
                }, 1000);
            }
        } catch (error) {
            console.error(error);
            setResetBtn('Reset Password');
            setError('Reset Failed. Please try again.');
        }
    };

    if(!token || !id){
        window.location.href="/login";
    }

    return (
        <div className="row">
            <div className="col-xs-12">
                <h3 className="text_black">Reset your Password</h3>
                <form onSubmit={onSubmit}>
                    {error && <div className="atom_error" data-aos="fade-left">{error}</div>}
                    <div className="fieldset">
                        <label htmlFor="password">New Password</label>
                        <input 
                            type="password" 
                            name="password" 
                            id="password" 
                            className="input-password" 
                            placeholder="Enter your new password" 
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="fieldset">
                        <label htmlFor="confirm-password">Confirm Password</label>
                        <input 
                            type="password" 
                            name="confirm-password" 
                            id="confirm-password" 
                            className="input-password" 
                            placeholder="Confirm your new password" 
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="fieldset_submit">
                        <input type="submit" name="submit" className="btn btn_green" value={resetBtn} />
                    </div>
                    <div className="fieldset">
                        <p>Go back to <a href="/login">Login</a></p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FormReset;
