import React, { useRef, useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';

const PreviewBid = ({ originalContent, handleEditTender }) => {
  const contentRef = useRef(null);
  const aiResponses = originalContent.ai[0].ai;

  // Initialize combinedHtml by joining all HTML content from aiResponses
  const [combinedHtml, setCombinedHtml] = useState(aiResponses.html);

  // Define the preventCopy function
  const preventCopy = (e) => {
    e.preventDefault();
    alert("Copying is not allowed!");
  };

  const handleDownloadPDF = async () => {
    const headerTemplate = `
      this is a header
    `;
  
    const footerTemplate = `
      This is a footer
    `;
  
    const element = document.createElement('div');
    element.innerHTML = `
      <style>
        body {
            font-size:14px;
            line-height:20px;
            font-family: "Inter";
        }
          h1,h2,h3,h4,h5,ul,li,table{
            font-family: "Inter";
          }
           th {
    padding: 10px 0;
    border-bottom: 2px solid #999;
    border-top: 2px solid #999;
}

th:last-child {
    text-align: right;
}

hr {
    margin: 70px auto 20px;
    color: #999;
    border-color: #999;
    background-color: #999;
    opacity: 0.4;
    width: 60%;
    page-break-before: always;
}

h1 {
    text-align: left;
    text-transform:uppercase;
    letter-spacing:-3px;
    font-size: 60px;
    font-family: "Inter";
    font-weight:900;
    padding: 40px 20px 0 0;
    margin-bottom:0px;
    margin-top:490px;
}


h2 {
    text-align: left;
    font-size: 18px;
    text-transform: uppercase;
    color: #999 !important;
    font-family: "Inter";
    padding: 40px 0 8px 0;
    margin: 0;
}

h3 {
    text-align: center;
    font-size: 18px;
    font-family: "Inter";
    padding: 10px 0;
    margin: 0;
}

h4 {
    text-align: left;
    font-size: 16px;
    font-family: "Inter";
    padding: 25px 0 0 0;
    margin: 0;
}

h5 {
    text-align: left;
            font-size: 14px;
            font-family: "Inter";
            padding: 15px 0 0 0;
            margin: 0 0 -10px;
            font-weight: 700;
}

p, li {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-family: "Inter";
    padding: 10px 0 0 0;
    margin: 0;
}

blockquote {
    font-family:"Inter";
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    background: #eaeaea;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    margin: 20px 0;
}

table {
    width: 100%;
    border: 0;
    border-collapse: collapse;
    margin: 20px 0;
    border-bottom: 2px solid #999;
}

table tr:first-child td {
    border-top: 1px solid #999;
}

table td {
    border-bottom: 1px solid #999;
    padding: 10px 0;
    font-size: 12px;
}

table td:last-child {
    text-align: right;
}

table td p {
    text-align: right;
}

table td p {
    font-size: 12px;
    padding: 0;
}

      </style>
      ${combinedHtml}
    `;
    document.body.appendChild(element);
  
    try {
      await html2pdf()
        .from(element)
        .set({
          margin:       [15, 15, 15, 15],
          filename: originalContent.ai[0].static.staticTenderTitle + '.pdf',
            image:        { type: 'jpeg',quality: 0.98 },
            html2canvas:  { scale: 2, logging: true, dpi: 192, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'p' },
            pagebreak: { before: 'h2', avoid: 'table' }

        }).toPdf()
        .get('pdf').then(function (pdf) {
          var totalPages = pdf.internal.getNumberOfPages();

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(15, 15, ''+originalContent.ai[0].static.staticTenderTitle+' | Tender Spcification');
            pdf.setDrawColor('#ddd'); // Set line color
                    const pageWidth = pdf.internal.pageSize.getWidth();
                    const yPosition1 = 18; // Adjust this value as needed
                    pdf.setLineWidth(.2); // Set line width
                    pdf.line(10, yPosition1, pageWidth - 10, yPosition1); // Draw line with margin

                    // Draw line above 'Tender Specification'
                    const yPosition2 = pdf.internal.pageSize.getHeight() - 15; // Adjust this value as needed
                    pdf.line(10, yPosition2, pageWidth - 10, yPosition2); // Draw line

                    pdf.text('Version 1.0', pdf.internal.pageSize.getWidth() - 200, pdf.internal.pageSize.getHeight() - 8);
            pdf.text('Tender Specification | ' + i + '/' + totalPages+'', pdf.internal.pageSize.getWidth() - 57, pdf.internal.pageSize.getHeight() - 8);
          } 
        }).save();
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  
    document.body.removeChild(element);
  };

  // Prevent text selection
  useEffect(() => {
    const preventSelection = (e) => {
      e.preventDefault();
    };

    const ref = contentRef.current;
    if (ref) {
      ref.addEventListener('selectstart', preventSelection);
      ref.addEventListener('copy', preventCopy);
    }

    return () => {
      if (ref) {
        ref.removeEventListener('selectstart', preventSelection);
        ref.removeEventListener('copy', preventCopy);
      }
    };
  }, []);

  return (
    <div className="atom_document_edit">
      <div className="atom_document_area">
        <div
          className="paper"
          contentEditable={false}
          ref={contentRef}
        >
            <div dangerouslySetInnerHTML={{ __html: originalContent.ai[0].ai.html }} />
        </div>
      </div>
      <div className="atom_document_buttons">
        <button className="btn btn_blue mini edit" onClick={handleEditTender}>
          Edit
        </button>
        <button className="btn btn_coral mini generate" onClick={handleDownloadPDF}>
          Generate PDF
        </button>
      </div>
    </div>
  );
};

export default PreviewBid;
