import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export const login = async (email, password) => {
    try {
        const response = await axios.post(`${API_URL}/auth/login`, {
            email,
            password
        }, {
            headers: {
                'STM-API-SECURE-KEY': API_KEY
            }
        });

        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
            return { success: false, errors: error.response.data.errors };
        }
        console.error('Login error:', error);
        throw error;
    }
};

export const forgotten = async (email) => {
    try {
        const response = await axios.post(`${API_URL}/auth/forgotten`, {
            email,
        }, {
            headers: {
                'STM-API-SECURE-KEY': API_KEY
            }
        });

        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
            return { success: false, errors: error.response.data.errors };
        }
        console.error('Login error:', error);
        throw error;
    }
};


export const reset = async (token, id, password) => {

    try {
        const response = await axios.post(`${API_URL}/auth/reset`, {
            userId: id,
            token: token,
            password: password
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'STM-API-SECURE-KEY': API_KEY
            }
        });

        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
            return { success: false, errors: error.response.data.errors };
        }
        console.error('Login error:', error);
        throw error;
    }
};


export const logout = async (token) => {
    try {
        console.log(token);
        console.log('token here')
        const response = await axios.get(`${API_URL}/auth/logout`,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'STM-API-SECURE-KEY': API_KEY
            }
        });

        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
            return { success: false, errors: error.response.data.errors };
        }
        console.error('Logout error:', error);
        throw error;
    }
};


export const signup = async (name, email, password) => {
    try {
        const response = await axios.post(`${API_URL}/auth/register`, {
            name: name,
            email: email,
            password: password
        },{
            headers: {
                'STM-API-SECURE-KEY': API_KEY
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getUser = async (token) => {
    console.log('function: getUser - ' + token);
    try {
        const response = await axios.post(`${API_URL}/app/user`, {},{
            headers: {
                'Authorization': `Bearer ${token}`,
                'STM-API-SECURE-KEY': API_KEY
            }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
            return { success: false, errors: error.response.data.errors };
        }
        console.error('Get User Error:', error);
        throw error;
    }
};
