import React, { useContext, useState } from 'react';


// Components
import Navigation from '../../components/global/nav_comp--main';
import AccountDropdown from '../../components/global/nav_comp--account';
import GlobalParagraph from '../../components/global/type_comp--paragraph';
import ProfileNavigation from '../../components/profile/profile_comp--nav';
import FormDetailsRefer from '../../forms/form_details--refer';
import BgAnimation from '../../modules/general/animation_bg--circle';

const Profile_DeletePage = () => {
    
    return (
        <>
            <BgAnimation/>
            <div className="container container_width--full layout_profile">
                <div className="row layout_main">
                    <AccountDropdown/>
                    <div className="col-xs-12 col-sm-1 mobile_hide">
                        <Navigation mobile={false} active="settings"/>
                    </div>
                    <div className="col-xs-12 col-sm-11 ">
                        <GlobalParagraph subtitle="Delete your Account"/>
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-md-3">
                                    <ProfileNavigation active="delete"/>
                                </div>
                                <div className="col-xs-12 col-md-9">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile_DeletePage;