import React, { useState, useContext, useEffect } from 'react';
import { postCompanySave } from '../../services/profileService';
import { AuthContext } from '../../contexts/authContext';
import MultiInput from '../modules/specifications/forms/multi_mod--list';

const FormDetailsCompany = () => {
    const { user, details, updateUserDetails } = useContext(AuthContext);

    // -- Variables
    const [saveBtn, setSaveBtn] = useState('Save Company Details');
    const [error, setError] = useState('');
    
    // -- Multi Input items
    const [qualifications, setQualifications] = useState([{ id: 1, value: '' }]);
    const [compliance, setCompliance] = useState([{ id: 1, value: '' }]);
    const [special, setSpecial] = useState([{ id: 1, value: '' }]);

    // -- Form
    const [formData, setFormData] = useState({});

    useEffect(() => {
        if (details?.company) {
            const initialFormData = {};
            Object.keys(details.company).forEach((key) => {
                initialFormData[key] = details.company[key] || '';
            });
            Object.keys(details.company_details).forEach((key) => {
                initialFormData[key] = details.company_details[key] || '';
            });
            setFormData(initialFormData);

            // -- Set Multi Input 
            if (details.company_details?.accred_qualifications) {
                const initialQuals = JSON.parse(details.company_details.accred_qualifications).map((value, index) => ({
                    id: index + 1,
                    value
                }));
                setQualifications(initialQuals);
            }

            if (details.company_details?.accred_compliance) {
                const initialComp = JSON.parse(details.company_details.accred_compliance).map((value, index) => ({
                    id: index + 1,
                    value
                }));
                setCompliance(initialComp);
            }

            if (details.company_details?.accred_special) {
                const initialSpec = JSON.parse(details.company_details.accred_special).map((value, index) => ({
                    id: index + 1,
                    value
                }));
                setSpecial(initialSpec);
            }
        }
    }, [details]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };


    const onSubmit = async (event) => {
        event.preventDefault();
        setSaveBtn('Saving...');

        const qualificationsJson = JSON.stringify(qualifications.map((a) => a.value));
        const complianceJson = JSON.stringify(compliance.map((a) => a.value));
        const specialJson = JSON.stringify(special.map((a) => a.value));

        const updatedFormData = {
            ...formData,
            accred_qualifications: qualificationsJson,
            accred_compliance: complianceJson,
            accred_special: specialJson,
        };

        try {
            const response = await postCompanySave(user.token, updatedFormData);
            if (response.success) {
                updateUserDetails(response.result.user);
                setError('');
                setSaveBtn('Saved!');
                setTimeout(() => {
                    setSaveBtn('Save Company Details');
                }, 3000);
            } else {
                setError('Failed to save company details.');
            }
        } catch (error) {
            console.error('Error saving company details:', error);
            setSaveBtn('Save Company Details');
            setError('Something went wrong on save. Please try again.');
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <div className="fieldset_submit float">
                {error && <div className="atom_error" data-aos="fade-left">{error}</div>}
                <input type="submit" name="submit" className="btn btn_green save" value={saveBtn} />
                <input
                    type="hidden"
                    name="company_uuid"
                    id="company_uuid"
                    value={formData.company_uuid}
                />
            </div>
            <div className="row form_section">
                <div className="col-xs-12 col-md-6">
                    <h6>Company Details</h6>

                    <div className="fieldset">
                        <label>Company Name</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Company Name"
                            value={formData.name || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Company Registration Number</label>
                        <input
                            type="text"
                            name="company_registration"
                            id="company_registration"
                            placeholder="Registration Number"
                            value={formData.company_registration || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Tell us about your company</label>
                        <textarea
                            name="company_about"
                            id="company_about"
                            value={formData.company_about || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Mission Statement</label>
                        <textarea
                            name="mission_statement"
                            id="mission_statement"
                            value={formData.mission_statement || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Trading Name (if applicable):</label>
                        <input
                            type="text"
                            name="trading_name"
                            id="trading_name"
                            placeholder="Trading Name"
                            value={formData.trading_name || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Street Address</label>
                        <input
                            type="text"
                            name="address_street"
                            id="address_street"
                            placeholder="Street Address"
                            value={formData.address_street || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>City</label>
                        <input
                            type="text"
                            name="address_city"
                            id="address_city"
                            placeholder="City"
                            value={formData.address_city || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Postcode</label>
                        <input
                            type="text"
                            name="address_postcode"
                            id="address_postcode"
                            placeholder="Postcode"
                            value={formData.address_postcode || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Number of Employees:</label>
                        <select name="employee_number" id="employee_number" value={formData.employee_number || ''}
                            onChange={handleChange}>
                            <option value="">Select One...</option>
                            <option value="9">1-9</option>
                            <option value="49">10-49</option>
                            <option value="249">50-249</option>
                            <option value="250">250+</option>
                        </select>
                    </div>
                    <h6>Social Value</h6>
                    <div className="fieldset">
                        <label>Select Relevant Category</label>
                        <select name="social_value" id="social_value" value={formData.social_value || ''}
                            onChange={handleChange}>
                            <option value="">Select One...</option>
                            <option value="Equality and Diversity: Promoting fairness, inclusivity, and equal opportunities for all individuals, regardless of their background, ethnicity, gender, or other characteristics.">Equality and Diversity: Promoting fairness, inclusivity, and equal opportunities for all individuals, regardless of their background, ethnicity, gender, or other characteristics.</option>
                            <option value="Corporate Social Responsibility: Taking responsibility for the company's impact on society and the environment, including initiatives such as philanthropy, volunteering, and ethical business practices.">Corporate Social Responsibility: Taking responsibility for the company's impact on society and the environment, including initiatives such as philanthropy, volunteering, and ethical business practices.</option>
                            <option value="Community Engagement: Engaging with local communities and supporting their development through partnerships, sponsorship, and involvement in community projects.">Community Engagement: Engaging with local communities and supporting their development through partnerships, sponsorship, and involvement in community projects.</option>
                            <option value="Ethical Business Practices: Conducting business in an ethical and transparent manner, adhering to high standards of integrity, honesty, and accountability.">Ethical Business Practices: Conducting business in an ethical and transparent manner, adhering to high standards of integrity, honesty, and accountability.</option>
                            <option value="Employee Well-being: Prioritizing the health, safety, and overall well-being of employees by providing a supportive work environment, work-life balance, and opportunities for growth and development."> Employee Well-being: Prioritizing the health, safety, and overall well-being of employees by providing a supportive work environment, work-life balance, and opportunities for growth and development.</option>
                            <option value="Innovation and Technology: Embracing innovation, technological advancements, and digital transformation to drive positive change and improve efficiency.">Innovation and Technology: Embracing innovation, technological advancements, and digital transformation to drive positive change and improve efficiency.</option>
                            <option value="Health and Wellness: Promoting and supporting initiatives that improve health, well-being, and access to healthcare services.">Health and Wellness: Promoting and supporting initiatives that improve health, well-being, and access to healthcare services.</option>
                        </select>
                    </div>
                    <div className="fieldset">
                        <label>Provide details of how the Company creates this value</label>
                        <textarea
                            name="social_value_about"
                            id="social_value_about"
                            value={formData.social_value_about || ''}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-xs-12 col-md-6">
                    <h6>Industry Relevant Accreditations</h6>
                    <div className="fieldset">
                        <label>Qualifications obtained by the Company relevant to the industry:</label>
                        <MultiInput
                            items={qualifications}
                            setItems={setQualifications}
                        />
                    </div>

                    <div className="fieldset">
                        <label>Compliance with Standards relevant to the industry:</label>
                        <MultiInput
                            items={compliance}
                            setItems={setCompliance}
                        />
                    </div>

                    <div className="fieldset">
                        <label>Special Achievements and Recognitions:</label>
                        <MultiInput
                            items={special}
                            setItems={setSpecial}
                        />
                    </div>
                  
                    <h6>Online Presence</h6>
                    <div className="fieldset">
                        <label>Website</label>
                        <input
                            type="text"
                            name="social_url"
                            id="social_url"
                            placeholder="https://"
                            value={formData.social_url || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Twitter</label>
                        <input
                            type="text"
                            name="social_twitter"
                            id="social_twitter"
                            placeholder="https://"
                            value={formData.social_twitter || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>LinkedIn</label>
                        <input
                            type="text"
                            name="social_linkedin"
                            id="social_linkedin"
                            placeholder="https://"
                            value={formData.social_linkedin || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Instagram</label>
                        <input
                            type="text"
                            name="social_instagram"
                            id="social_instagram"
                            placeholder="https://"
                            value={formData.social_instagram || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Facebook</label>
                        <input
                            type="text"
                            name="social_facebook"
                            id="social_facebook"
                            placeholder="https://"
                            value={formData.social_facebook || ''}
                            onChange={handleChange}
                        />
                    </div>
                    
                </div>
            </div>
        </form>
    );
};

export default FormDetailsCompany;
