import React from 'react';

// Images
import logoIcon from '../../../img/icons/stm_logo_icon.svg';
import screenExample from '../../../img/screens/example_screen.png';
import FormReset from '../../forms/form_page--reset';


const ResetPage = () => {
    return (
        <div className="container container_width--full layout_signup">
            <div className="row">
                <div className="col-xs-12 col-md-6 bg_blue height_full" data-aos="fade-right">
                    <div className="inner-wrap flex-column">
                        <div className="flex-top">
                            <img src={logoIcon} alt="Search the Marketplace" />
                            <h1 className="text_white ft_w_600">Welcome to Search the Marketplace</h1>
                            <p className="text_white">Our platform lets you easily create documents to suit your needs. Use it to bring your ideas to life.</p>
                        </div>
                        <div className="flex-bottom" >
                            <img src={screenExample} alt="STM Example" data-aos="fade-up" className="height_100 width_100"/>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6 bg_gradient_xlblue height_full">
                    <div className="inner-wrap" data-aos="fade-left">    
                        <FormReset/>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default ResetPage;