import React, { useContext, useState } from 'react';
import { AuthContext } from '../contexts/authContext';
import { fetchTender, saveTender, generateHTMLforEdit, saveTenderResponses } from '../services/proposalService';



// ------- Tender Specification Hooks
// --
// -- Tender Specifican hooks

const useTender = (tender) => {
    const { user } = useContext(AuthContext); // Get Current User
    const [tenderDetails, setTenderDetails] = useState(null);
    const [loading, setLoading] = useState(true); 

    const getTender = async () => {
        if (!user || !tender) {
            setLoading(false); // Set loading to false if no user or tender
            return;
        }

        try {
            const details = await fetchTender(user.token, tender);
            setTenderDetails(details.result.tenders.revisions);
        } catch (error) {
            console.error('Error fetching tender details:', error);
        } finally {
            setLoading(false); // Ensure loading is set to false after fetch
        }
    };

    const createTender = async (responseUser, tender, name, revisionChanged, revision) => {
        try {
            const response = await generateHTMLforEdit(user.token, responseUser, tender, name, revisionChanged, revision);
            return response.result;
            // Handle the successful creation of the tender here
        } catch (error) {
            console.error('There was a problem with the create tender request:', error);
        }
    };

    const saveTender = async (responseUser, tender, name, revisionChanged, revision) => {
        try {
            const response = await saveTenderResponses(user.token, responseUser, tender, name, revisionChanged, revision);
            return response.result;
            // Handle the successful creation of the tender here
        } catch (error) {
            console.error('There was a problem with the create tender request:', error);
        }
    };


    return {
        tenderDetails,
        loading,
        getTender,
        createTender,
        saveTender
    };
};

export default useTender;
