import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ element }) => {
    const { user, loading, details } = useContext(AuthContext);
    const [isUserLoaded, setIsUserLoaded] = useState(false);

    // Wait for the user data to be loaded
    useEffect(() => {
        if (!loading) {
            setIsUserLoaded(true);
        }
    }, [loading]);

    // Render loading state while user data is being fetched
    if (!isUserLoaded) {
        return <div>Loading...</div>;
    }

    // Once user data is loaded, determine whether to render the protected route or navigate to login
    return user ? element : <Navigate to="/login" />;
};
