import React from 'react';

// Images
import plusIcon from "../../../img/icons/icon_plus.svg"
import { Link } from 'react-router-dom';



const CardCreate= ({ title, url }) => {
    return (
      
            <div className="col-xs-12 col-md-4">
                <Link to={url}>
                    <div className="atom_cards atom_cards_create">
                        <img src={plusIcon} alt={title}/>
                        <p>{title}</p>
                    </div>
                </Link>
            </div>
       
       
    );
}

export default CardCreate;