import React, { useContext, useState } from 'react';

const TenderUploadForBid = ({actionBtnChange,fileName, handleFileChange, handleUpload, error}) => {
  
  return (
    <div className="atom_loading with_opacity">
      <div className="atom_cards atom_cards_create">
       {error && <div className="atom_error" data-aos="fade-left">{error}</div>}
        <h5>Upload Tender to start Bid Proposal</h5>
        <p>Please upload your document to make sure there is stuff.</p>
        
        {/* Hidden file input */}
        <label htmlFor="file-upload" className="input-file">
          {fileName}
          <input
            type="file"
            accept=".pdf,.doc,.docx"
            id="file-upload"
            name="file-upload"
            className="sr-only"
            onChange={handleFileChange}
          />
        </label>
        
        <button
          type="button"
          className="btn btn_blue file-upload"
          onClick={handleUpload}
        >
          {actionBtnChange}
        </button>
      </div>
    </div>
  );
};

export default TenderUploadForBid;
