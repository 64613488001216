import React, { createContext, useState, useEffect } from 'react';
import { login, logout, getUser, forgotten, reset } from '../services/authService';

export const AuthContext = createContext({
    user: null,
    details: null
});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    // BIDS
    const [bid, setBid] = useState([]);

    // Tenders
    const [tender, setTender] = useState([]);

    // FUNCTIONS -- Bids
    const setBids = (title) => {
        setBid(title);
        // add more as appropriate
    };

    // FUNCTIONS -- Tenders
    const setTenders = (title) => {
        setTender(title);
        // add more as appropriate
    };

    const fetchUserDetails = async (token) => {
        try {
            const userDetails = await getUserDetails(token);
            setDetails(userDetails);
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const handleLogin = async (email, password) => {
        try {
            const data = await login(email, password);

            if (data.success) {
                // Store the session token in localStorage
                localStorage.setItem('authSessionToken', data.result.session.token);

                // Update the user state with the token
                setUser({ token: data.result.session.token });
                console.log(data.result.session.token);

                // Fetch and set the user details
                fetchUserDetails(data.result.session.token);
            }
            return data;
        } catch (error) {
            console.error('Error during login:', error);
            return { success: false, error: 'Internal Server Error' };
        }
    };

    const handleForgotten = async (email) => {
        try {
            const data = await forgotten(email);

            if (data.success) {
                console.log(data)
                return data;
            }
            return data;
        } catch (error) {
            console.error('Error during login:', error);
            return { success: false, error: 'Internal Server Error' };
        }
    };

    const handleReset = async (token, id, password) => {
        try {
            const data = await reset(token, id, password);

            if (data.success) {
                console.log(data)
                return data;
            }
            return data;
        } catch (error) {
            console.error('Error during login:', error);
            return { success: false, error: 'Internal Server Error' };
        }
    };

    const handleLogout = async () => {
        try {
            const data = await logout(user.token);

            if (data.success) {
                // Store the session token in localStorage
                localStorage.removeItem('authSessionToken');
                setUser(null);
                setDetails(null);
            }
            return data;
        } catch (error) {
            console.error('Error during login:', error);
            return { success: false, error: 'Internal Server Error' };
        }
        
    };

    const updateUserDetails = async () => {
        if (user && user.token) {
            await fetchUserDetails(user.token);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('authSessionToken');
        if (token) {
            setUser({ token });
            if(!details){
                fetchUserDetails(token);
            }
        }
        setLoading(false);
    }, []);

    return (
        <AuthContext.Provider value={{ user, details, loading, bid, handleLogin, handleLogout, handleForgotten, handleReset, setBids, setTenders, updateUserDetails }}>
            {children}
        </AuthContext.Provider>
    );
};

export const getUserDetails = async (token) => {
    try {
        const response = await getUser(token);
        console.log(response);
        if (!response.success) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.result.user;
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
};
