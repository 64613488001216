import { validatePassword } from "./validateService";
import React from 'react';

export const generatePassword = () => {
    const length = 8;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    while (true) {
        password = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        if (validatePassword(password)) break; // Ensure password meets the criteria
    }
    return password;
};

const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
};

const formatDate = (unixTimestamp) => {
    // Convert Unix timestamp (seconds) to milliseconds
    const milliseconds = unixTimestamp * 1000;

    // Create a new Date object from milliseconds
    const date = new Date(unixTimestamp);

    // Define months and weekdays
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    // Extract date components
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    const weekday = weekdays[date.getDay()];
    const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false });

    // Return formatted date string
    return `${weekday}, ${day}${getOrdinalSuffix(day)} ${month} ${year} ${time}`;
};

const TimestampToDate = ({ timestamp }) => {
    // Call formatDate with the unix timestamp
    const formattedDate = formatDate(timestamp);

    return (
        <>
            {formattedDate}
        </>
    );
};

export default TimestampToDate;
