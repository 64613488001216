import React, { useContext, useState } from 'react';


const SystemMiniAnswer = ({questionNumberCurrent, onEditResponse, isEmpty}) => {
    
    return (
        <>
            {questionNumberCurrent > 4 ?(
                <div className={`atom_answer atom_answer_mini ${isEmpty}`} onClick={() => onEditResponse(questionNumberCurrent)}>
                    {questionNumberCurrent}
                </div>
            ):(
                <div className={`atom_answer atom_answer_mini disabled ${isEmpty}`}>
                    {questionNumberCurrent}
                </div>
            )
            }
        </>
    );
}

export default SystemMiniAnswer;