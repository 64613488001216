import React from 'react';

// Images
import { Link} from 'react-router-dom/dist';


const ProfileNavigation= ({active}) => {
    return (
        <ul>
            <li>
                <Link to="/dashboard/profile/settings" className={`${active === 'settings' ? 'active' : ''}`}>
                    Edit Profile
                </Link>
            </li>
            <li>
                <Link to="/dashboard/profile/settings/password" className={`${active === 'password' ? 'active' : ''}`}>
                    Password
                </Link>
            </li>
            <li>
                <Link to="/dashboard/profile/settings/refer" className={`${active === 'refer' ? 'active' : ''}`}>
                    Refer a Friend
                </Link>
            </li>
            <li>
                <Link to="/dashboard/profile/settings/delete" className={`${active === 'delete' ? 'active' : ''}`}>
                    Delete Account
                </Link>
            </li>
        </ul>
    );
}

export default ProfileNavigation;