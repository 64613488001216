import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import Navigation from '../../components/global/nav_comp--main';
import AccountDropdown from '../../components/global/nav_comp--account';
import BgAnimation from '../../modules/general/animation_bg--circle';
import SpecificationTenderEdit from '../../components/specifications/spec_comp--tender--edit';


const Tender_EditPage = () => {
    const { id, revision } = useParams();
    const[disabled, setDisabled] = useState(true)
    return (
        <>
            <BgAnimation/>
            <div className="container container_width--full">
                <div className="row layout_main">
                    <AccountDropdown/>
                    <div className="col-xs-12 col-sm-1 mobile_hide">
                        <Navigation mobile={false} active="tender"/>
                    </div>
                    <div className="col-xs-12 col-sm-11 ">
                        <div className="row atom_spec_area">
                            <SpecificationTenderEdit tender={id} revision={revision} disabled={disabled}/>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
}

export default Tender_EditPage;